
import { API } from 'aws-amplify';

const getEmailBodyFromS3 = async (objectKey) => {
  const path = "pushpin/read";
  console.log(`Executing API.get on https://${process.env.REACT_APP_API_ENVIRONMENT}.api.amazonavoc.com/${path}`)

  var handleError = function (err) {
    console.warn(err);
    return new Response(JSON.stringify({
        code: 400,
        message: 'Error executing getEmailBody function'
    }));
  };

  const response = await (API.get(
    process.env.REACT_APP_API_ENVIRONMENT, 
    path, {
      headers: {
        'Cache-Control': 'max-age=87000',
        'Content-type': 'application/json,text/plain,text/html',
        // 'Content-type': '*/*',
      },
      queryStringParameters: {
        action : 'get_html_body',
        s3_bucket: 'dv-live-received-email',
        s3_objectkey: objectKey
      }
    }).catch(handleError));

    const data = await response;
    console.log(data) //DEBUG AAM
    return data;
}

const scanDBitems = async () => {
  const path = "pinboard/scan";
  console.log(`Executing API.get on https://${process.env.REACT_APP_API_ENVIRONMENT}.api.amazonavoc.com/${path}`)

  var handleError = function (err) {
    console.warn(err);
    return new Response(JSON.stringify({
        code: 400,
        message: 'Error executing scanDBitems function'
    }));
  };

  const response = await (API.get(
    process.env.REACT_APP_API_ENVIRONMENT, 
    path, {
      headers: {
        'Cache-Control': 'no-cache',
        'Content-type': 'application/json',
      },
      queryStringParameters: {
        action : "scan_records"
      }
    }).catch(handleError));

    const data = await response;
    // console.log(data); //DEBUG AAM
    return data;
}

const scanDBitemsDateRange = async (startDate, endDate) => {
  const path = "pinboard/scan";
  console.log(`Executing API.get on https://${process.env.REACT_APP_API_ENVIRONMENT}.api.amazonavoc.com/${path}`)

  var handleError = function (err) {
    console.warn(err);
    return new Response(JSON.stringify({
        code: 400,
        message: 'Error executing scanDBitemsDateRange function'
    }));
  };

  const response = await (API.get(
    process.env.REACT_APP_API_ENVIRONMENT, 
    path, {
      headers: {
        'Cache-Control': 'max-age=87000',
        'Content-type': 'application/json',
      },
      queryStringParameters: {
        action : "scan_records_date",
        start_date : startDate,
        end_date : endDate,
      }
    }).catch(handleError));

    const data = await response;
    console.log(data) //DEBUG AAM
    return data;
}

const scanDBitemsMaintenance = async () => {
  const path = "pinboard/scan";
  console.log(`Executing API.get on https://${process.env.REACT_APP_API_ENVIRONMENT}.api.amazonavoc.com/${path}`)

  var handleError = function (err) {
    console.warn(err);
    return new Response(JSON.stringify({
        code: 400,
        message: 'Error executing scanDBitemsMaintenance function'
    }));
  };

  const response = await (API.get(
    process.env.REACT_APP_API_ENVIRONMENT, 
    path, {
      headers: {
        'Cache-Control': 'max-age=87000',
        'Content-type': 'application/json',
      },
      queryStringParameters: {
        action : "scan_records_maintenance"
      }
    }).catch(handleError));

    const data = await response;
    console.log(data) //DEBUG AAM
    return data;
}

const deleteDBitem = async (id ) => {
  const path = "pinboard/delete";
  console.log(`Executing API.post on https://${process.env.REACT_APP_API_ENVIRONMENT}.api.amazonavoc.com/${path}`)
  console.log(`Deleting record for id : ${id}` ) //DEBUG AAM
  

  var handleError = function (err) {
    console.warn(err);
    return new Response(JSON.stringify({
        code: 400,
        message: `Error deleting record with id : ${id} `
    }));
  };

  const response = await (API.post(
    process.env.REACT_APP_API_ENVIRONMENT, 
    path, {
      response: true,
      body: {
        action : "delete_record",
        id : id
      }
    }).catch(handleError));
  
    const data = await response;
    console.log(data) //DEBUG AAM
    return data;
}


const readDBitem = async (id ) => {
  const path = "pinboard/read";
  console.log(`Executing API.post on https://${process.env.REACT_APP_API_ENVIRONMENT}.api.amazonavoc.com/${path}`)
  console.log(`Getting data for record with id ${id}` ) //DEBUG AAM


  var handleError = function (err) {
    console.warn(err);
    return new Response(JSON.stringify({
        code: 400,
        message: `Error reading record with id: ${id} `
    }));
  };

  const response = await (API.post(
    process.env.REACT_APP_API_ENVIRONMENT, 
    path, {
      response: true,
      body: {
        action : "read_record",
        id : id
      }
    }).catch(handleError));
  
    const data = await response;
    console.log(data) //DEBUG AAM
    return data;
}

const updateDBitem = async (record ) => {
  const path = "pinboard/update";
  console.log(`Executing API.post on https://${process.env.REACT_APP_API_ENVIRONMENT}.api.amazonavoc.com/${path}`)
  console.log(`Updating record : ${record}` ) //DEBUG AAM

  var handleError = function (err) {
    console.warn(err);
    return new Response(JSON.stringify({
        code: 400,
        message: `Error updating item : ${record} `
    }));
  };

  const response = await (API.post(
    process.env.REACT_APP_API_ENVIRONMENT, 
    path, {
      response: true,
      body: {
        action : "update_record",
        item : record
      }
    }).catch(handleError));
  
    const data = await response;
    console.log(data) //DEBUG AAM
    return data;
}

const createDBitem = async (record ) => {
  const path = "pinboard/create";
  console.log(`Executing API.post on https://${process.env.REACT_APP_API_ENVIRONMENT}.api.amazonavoc.com/${path}`)
  console.log(`Creating record : ${record}` ) //DEBUG AAM

  var handleError = function (err) {
    console.warn(err);
    return new Response(JSON.stringify({
        code: 400,
        message: `Error creating item : ${record} `
    }));
  };

  const response = await (API.post(
    process.env.REACT_APP_API_ENVIRONMENT, 
    path, {
      response: true,
      body: {
        action : "create_record",
        item : record
      }
    }).catch(handleError));
  
    const data = await response;
    console.log(data) //DEBUG AAM
    return data;
}

//const emosDBitem = async (id, startDate, endDate, timeZone, propertiesImpacted) => {
const emosDBitem = async (values) => {
  const path = "pinboard/emos";
  console.log(`Executing API.get on https://${process.env.REACT_APP_API_ENVIRONMENT}.api.amazonavoc.com/${path}`)
  console.log(`Updating Overlaps With & Ops Plan for id : ${values.id}` ) //DEBUG AAM
  

  var handleError = function (err) {
    console.warn(err);
    return new Response(JSON.stringify({
        code: 400,
        message: `Error updating Overlaps With & Ops Plan with id : ${values.id} `
    }));
  };

  const response = await (API.get(
    process.env.REACT_APP_API_ENVIRONMENT, 
    path, {
      headers: {
        'Cache-Control': 'no-cache',
        'Content-type': 'application/json',
      },
      queryStringParameters: {
        action : "query_events_by_time",
        id : values.id,
        maint_start_time: values.startDate,
        maint_end_time: values.endDate,
        maint_timezone: values.timeZone,
        properties_impacted: values.propertiesImpacted
      }
    }).catch(handleError));
  
    const data = await response;
    console.log(data) //DEBUG AAM
    return data;
};

export {
  getEmailBodyFromS3,
  scanDBitems,
  scanDBitemsDateRange,
  scanDBitemsMaintenance,
  createDBitem,
  readDBitem,
  updateDBitem,
  deleteDBitem,
  emosDBitem
}
