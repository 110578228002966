import React, { useContext } from 'react';
import { Button, Modal } from 'react-bootstrap';

import { PbContext } from '../contexts/PbContextProvider';

export const DeleteFormModal = (props) => {
    const [ pbState, pbDispatch] = useContext(PbContext);
    
    console.log("DeleteFormModal props=") // DEBUG AAM
    console.log( props ) // DEBUG AAM
    console.log("pbState.deleteModalData=") //DEBUG AAM
    console.log(pbState.deleteModalData); //DEBUG AAM
    
    const handleClose = () => { 
        pbDispatch({ type: 'SET_SHOW_DELETE_MODAL', showDeleteModal:  false })
        pbDispatch({ type: 'SET_DELETE_MAINTENANCE_REQUEST', deleteMaintenanceRequest:  false });
    };
    const handleDeleteMaintenanceRequest = () => {
        pbDispatch({ type: 'SET_DELETE_MAINTENANCE_REQUEST', deleteMaintenanceRequest:  true });
        pbDispatch({ type: 'SET_SHOW_DELETE_MODAL', showDeleteModal:  false });
        // props.deleteFormFunction(props.deleteTicketID);
        // console.log("pbState.deleteModalData=") //DEBUG AAM
        // console.log(pbState.deleteModalData); //DEBUG AAM
        if (pbState.deleteModalData.id){
            props.deleteFormFunction(pbState.deleteModalData.id);
            pbDispatch({ type: 'SET_DELETE_MODAL_DATA', deleteModalData: {} });
        } else {
            console.error(`Invalid record ID in  DeleteFormModal. Expected UUID got ${pbState.deleteModalData.id}`)
        }
        
    };

    return (
        <React.Fragment>
            { console.log("showing Modal DeleteFormModal !!!! ")}
            <Modal show={pbState.showDeleteModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Warning</Modal.Title>
                </Modal.Header>
                <Modal.Body>Do you really want to delete maintenance request <br /> <strong> {pbState.deleteModalData.id} </strong> ?</Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="outline-danger" onClick={handleDeleteMaintenanceRequest}>
                        Delete Request
                    </Button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>   
    )
}

export const InfoModal = (props) => {
    const [ pbState, pbDispatch] = useContext(PbContext);
    const handleClose = () => { 
        pbDispatch({ type: 'SET_SHOW_MODAL', showModal:  false });
        pbDispatch({ 
            type: 'SET_MODAL_VALUES',
            modalHeader: '',
            modalBody: ''
          });
    };

    return (
        <React.Fragment>
            { console.log("showing InfoModal ! ")}
            <Modal show={pbState.showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title> {pbState.modalHeader} </Modal.Title>
                </Modal.Header>
                <Modal.Body>  {pbState.modalBody}   </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-secondary" onClick={handleClose}>
                        Close
                    </Button>
                    
                </Modal.Footer>
            </Modal>
        </React.Fragment>   
    )
}
 
 