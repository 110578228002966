import React from "react";
import { useField, useFormikContext } from "formik";
import DatePicker from "react-datepicker";

export const PbDatePicker = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(props);
  return (
      <React.Fragment>
          <DatePicker
            {...field}
            {...props}
            className="form-control form-text"
            selected={(field.value && new Date(field.value)) || null}
            onChange={val => {
                console.log(new Date(val).toISOString());
                setFieldValue(field.name, val);
            }}
            />
            {meta.touched && meta.error ? (
                <div className="error">{meta.error}</div>
            ) : null
    }
      </React.Fragment>
  );
};

export default PbDatePicker;
