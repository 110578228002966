//Import configs
import { prodConfig } from './prodconfig';
import { gammaConfig } from './gammaconfig';

//Initialize local variables
let selectedConfig;
console.log('Node ENV', process.env.NODE_ENV)
console.log('React App ENV', process.env.REACT_APP_API_ENVIRONMENT)

//Auto Switch between different environments based on code branch and URL's
if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_API_ENVIRONMENT === "Prod") {
  console.log("Using Prod OAuth config and Prod API");
  selectedConfig = prodConfig;
}  else if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_API_ENVIRONMENT === "Gamma") {
  console.log("Using Gamma OAuth config and Gamma API");
  selectedConfig = gammaConfig;
} 
else {
  console.log("Using Gamma config until Local Oauth config is setup");
  selectedConfig = gammaConfig;
}
// selectedConfig = gammaConfig;

export default selectedConfig;
