import React, { createContext, useReducer } from 'react';
import { maintenanceFormInitialValues } from '../js/PbConstants';
  
const pbConextInitialState  =  {
    showViewCal: true,
    showReportData: false,
    showMaintenanceForm: false,
    showViewReports: false,
    showDayCal: false,
    dbDataReceived: false,
    showDBdata: false,
    fromReportViewer: false,
    searchByMonth: false,
    filterSortKey: 'startDate',
    currentMonth: 0,
    timeZone: 'GMT',
    viewData: [],
    maintenanceFormValues: maintenanceFormInitialValues,
    lastUpdatedBy: 'N/A',
    deleteModalData: {},
    currentUser: null,
    editRestrictions: true,
    clearRecords: true,
    showPendingPins: false,
    pendingPinsData: [],
    showS3DataWindow: false,
    showS3DataDiv: false,
    s3DataWindowDivOpen: false,
    fromPendingPins: false,
    s3ObjectKey: undefined,
}

const pbReducer = (pbState, pbAction) => {
    switch (pbAction.type) {
        case 'SET_S3_OBJECT_KEY':
            return {
                ...pbState,
                s3ObjectKey: pbAction.s3ObjectKey,
            };

        case 'FROM_PENDING_PINS':
            return {
                ...pbState,
                fromPendingPins: pbAction.fromPendingPins,
            };

        case 'S3_WINDOW_OPEN_STATUS':
            return {
                ...pbState,
                s3DataWindowDivOpen: pbAction.s3DataWindowDivOpen,
            };


        case 'SHOW_S3_DATA_DIV':
            return {
                ...pbState,
                showS3DataDiv: pbAction.showS3DataDiv,
            };

        case 'SHOW_S3_DATA_WINDOW':
            return {
                ...pbState,
                showS3DataWindow: pbAction.showS3DataWindow,
            };

        case 'SET_PENDING_PINS_DATA':
            return {
                ...pbState,
                pendingPinsData: pbAction.pendingPinsData,
            };
        

        case 'SHOW_PENDING_PINS':
            return {
                ...pbState,
                showPendingPins: pbAction.showPendingPins,
            };

        case 'SET_TOTAL_ENTRIES_STATS':
            return {
                ...pbState,
                totalEntriesStats: pbAction.totalEntriesStats,
            };

        case 'SET_CLEAR_RECORDS':
            return {
                ...pbState,
                clearRecords: pbAction.clearRecords,
            };

        case 'SET_CURRENT_USER':
            return {
                ...pbState,
                currentUser: pbAction.currentUser,
            };
        
        case 'SET_EDIT_RESTRICTIONS':
            return {
                ...pbState,
                editRestrictions: pbAction.editRestrictions,
            };

        case 'SET_FILTER_SORT_KEY':
            return {
                ...pbState,
                filterSortKey: pbAction.filterSortKey,
            };


        case 'SET_SELECTED_VIEWPANE_DATA':
            return {
                ...pbState,
                selectedViewPaneData: pbAction.selectedViewPaneData,
            };

        case 'SET_DB_DATA':
            return {
                ...pbState,
                dbData: pbAction.dbData,
            };

        case 'DB_DATA_RECEIVED':
            return {
                ...pbState,
                dbDataReceived: pbAction.dbDataReceived,
            };
            
        case 'FROM_REPORT_VIEWER':
            return {
                ...pbState,
                fromReportViewer: pbAction.fromReportViewer,
            };

        case 'SHOW_DB_DATA':
            return {
                ...pbState,
                showDBdata: true,
            };
        
        case 'SET_SHOW_DELETE_MODAL':
            return {
                ...pbState,
                showDeleteModal: pbAction.showDeleteModal,
            };
        
        case 'SET_DELETE_MODAL_DATA':
            return {
                ...pbState,
                deleteModalData: pbAction.deleteModalData,
            };

        case 'SET_SHOW_MODAL':
            return {
                ...pbState,
                showModal: pbAction.showModal,
            };

        case 'SET_MODAL_VALUES':
            return {
                ...pbState,
                modalHeader: pbAction.modalHeader,
                modalBody: pbAction.modalBody,
            };

        case 'SET_DELETE_MAINTENANCE_REQUEST':
            return {
                ...pbState,
                deleteMaintenanceRequest: pbAction.deleteMaintenanceRequest,
            };
            
        case 'SET_SEARCH_BY_MONTH':
            return {
                ...pbState,
                searchByMonth: pbAction.searchByMonth,
            };

        case 'SET_MFORM_VALUES':
            return {
                ...pbState,
                maintenanceFormValues: pbAction.maintenanceFormValues,
            };

        case 'SET_TIMEZONE':
            return {
                ...pbState,
                timeZone: pbAction.timeZone,
            };
        case 'SET_CURRENT_MONTH':
            return {
                ...pbState,
                currentMonth: pbAction.currentMonth,
            };
        case 'SHOW_VIEW_CALENDAR':
            return {
                ...pbState,
                showViewCal: true,
            };

        case 'HIDE_VIEW_CALENDAR':
            return {
                ...pbState,
                showViewCal: false,
            };
        
        case 'SHOW_REPORT_DATA':
            return {
                ...pbState,
                showReportData: true,
            };

        case 'HIDE_REPORT_DATA':
            return {
                ...pbState,
                showReportData: false,
            };
        
        case 'SHOW_MAINTENANCE_FORM':
            return {
                ...pbState,
                showMaintenanceForm: true,
            };

        case 'HIDE_MAINTENANCE_FORM':
            return {
                ...pbState,
                showMaintenanceForm: false,
            };

        case 'SHOW_VIEW_REPORTS':
            return {
                ...pbState,
                showViewReports: true,
            };

        case 'HIDE_VIEW_REPORTS':
            return {
                ...pbState,
                showViewReports: false,
            };

        case 'SHOW_DAY_CALENDAR':
            return {
                ...pbState,
                showDayCal: true,
            };

        case 'HIDE_DAY_CALENDAR':
            return {
                ...pbState,
                showDayCal: false,
            };
        case 'LASTUPDATEDBY':
            return {
                ...pbState,
                lastUpdatedBy: pbAction.lastUpdatedBy,
            }
        default: return pbState;
    }
}

const PbContext = createContext(undefined);

const PbContextProvider = ({children}) => {
    const pbContextValue = useReducer(pbReducer, pbConextInitialState );

    return(
        <PbContext.Provider value={pbContextValue}>
            {children}
        </PbContext.Provider>
    )
}

export {
    PbContext,
    PbContextProvider,
}