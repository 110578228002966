import React, { useContext, useState }  from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { Alert, Row, Col, Button, FormGroup} from 'react-bootstrap';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faUndo } from "@fortawesome/free-solid-svg-icons";

import { pbFormConfig, formErrorStyle, pickerDivStyle } from '../js/PbConstants';
import PbDatePicker from './DatePicker';
import { PbContext } from '../contexts/PbContextProvider';
// import { scanDB } from './PbDbUtils';
import { scanDBitems } from '../js/PbAPIUtils';
// import { DisplayFormikState } from './helper';


const SearchByMonth = () => {
    const initialValues = {
        reportStartDate: '',
        reportEndDate: '',
        checkBoxChecked: false,
      };

      const [ pbState, pbDispatch] = useContext(PbContext);
      const [ disableAllMonthsCheckBox , setDisableAllMonthsCheckBox] = useState(false);
      const [ filterFormValues, setFilterFormValues ] = useState(initialValues);
      const [ disableStartEndPicker , setDisableStartEndPicker] = useState(false);

    const handleFilterResetBtnClick = (formikProps) => {
        formikProps.isSubmitting = false;
        setFilterFormValues(initialValues);
        setDisableAllMonthsCheckBox(false);
        setDisableStartEndPicker(false);
        pbDispatch({ type: 'SET_CLEAR_RECORDS', clearRecords: true });
        pbDispatch({type: 'SHOW_DB_DATA', showDBdata: false});
    }
    
    /* Local functions */
    
    const handleFilterFormSubmit = (values, actions) => {
        actions.setSubmitting(false);
        console.dir(values);
        console.log((JSON.stringify(values, null, 2)));
        pbDispatch({ type: 'DB_DATA_RECEIVED', dbDataReceived: false });
        pbDispatch({ type: 'SET_CLEAR_RECORDS', clearRecords: false });
        pbDispatch({type: 'SHOW_DB_DATA', showDBdata: true});

        try {
            // scanDB().then(data => {
            scanDBitems().then( data => {
                console.dir(data);
                pbDispatch({ type: 'SET_DB_DATA', dbData: {...data} });
                pbDispatch({ type: 'DB_DATA_RECEIVED', dbDataReceived: true });

                //Nothing selected
                if ( !values.reportStartDate && !values.reportEndDate  && !values.checkBoxChecked){
                    alert("You need to select at least one date from picker or select 'Search All Months' checkbox");
                } 
                //Only "Search All Months" checkbox selected. Shows everything in DB
                else if  ( !values.reportStartDate && !values.reportEndDate  && values.checkBoxChecked){
                    pbDispatch({ type: 'SET_SELECTED_VIEWPANE_DATA', selectedViewPaneData: {...data} });
                } 
                // Both report start and end date selected. Shows data within start/end date range.
                else if ( values.reportStartDate && values.reportEndDate  && !values.checkBoxChecked ) {
                    let dataToDisplay = Object.keys(data).filter( idx =>  
                        data[idx]['startDate'].split('T')[0] >= values.reportStartDate.toISOString().split('T')[0] &&
                        data[idx]['startDate'].split('T')[0] <= values.reportEndDate.toISOString().split('T')[0] 
                    )
                    let filteredData = dataToDisplay.map( idx => data[idx]);
                    pbDispatch({ type: 'SET_SELECTED_VIEWPANE_DATA', selectedViewPaneData: {...filteredData} });
                } 
                // Only report start date selected. Shows all reports starting from selected date
                else if ( values.reportStartDate && !values.reportEndDate  && !values.checkBoxChecked ) {
                    let dataToDisplay = Object.keys(data).filter( idx =>  
                        data[idx]['startDate'].split('T')[0] >= values.reportStartDate.toISOString().split('T')[0] 
                    )
                    let filteredData = dataToDisplay.map( idx => data[idx]);
                    pbDispatch({ type: 'SET_SELECTED_VIEWPANE_DATA', selectedViewPaneData: {...filteredData} });
                }
                 // Only report end date selected. Shows all reports before selected end date
                else if ( !values.reportStartDate && values.reportEndDate  && !values.checkBoxChecked ) {
                    let dataToDisplay = Object.keys(data).filter( idx =>  
                        data[idx]['startDate'].split('T')[0] <= values.reportEndDate.toISOString().split('T')[0] 
                    )
                    let filteredData = dataToDisplay.map( idx => data[idx]);
                    pbDispatch({ type: 'SET_SELECTED_VIEWPANE_DATA', selectedViewPaneData: {...filteredData} });
                }
            });
        } catch (listDataErr) {
            console.log(listDataErr);
        }
        //Reset form last as it clears props values
        actions.resetForm();
        setDisableAllMonthsCheckBox(false);
        setDisableStartEndPicker(false); 
        // pbDispatch({type: 'SHOW_DB_DATA'});
        pbDispatch({type: 'SHOW_DB_DATA', showDBdata: false});
    }

    return (
        <React.Fragment>
            <Formik
                // validationSchema={validationSchema}
                initialValues={filterFormValues}
                onSubmit={handleFilterFormSubmit}
                enableReinitialize={true}
            > 
                {(formikProps) => ( 
                    <Form autoComplete="off">
                        <Row >
                            <FormGroup as={Col} controlId="reportStartDate">
                                <label htmlFor="reportStartDate" className="font-size-0-8" >Report Start Date</label>
                                <div style={pickerDivStyle} >
                                    <div style={formErrorStyle} className={formikProps.errors.reportStartDate && formikProps.touched.reportStartDate ? 'pickerError' : null}>
                                    <PbDatePicker 
                                        className="form-control font-size-0-8"
                                        id="reportStartDate"
                                        name='reportStartDate'
                                        placeholderText={"yyyy-mm-dd"}
                                        dateFormat='yyyy-MM-dd'
                                        value={formikProps.values.reportStartDate}
                                        errors={formikProps.errors.reportStartDate}
                                        touched={formikProps.touched.reportStartDate} 
                                        selected={formikProps.values.reportStartDate}
                                        onBlur={formikProps.handleBlur}
                                        onSelect={ async e => {setDisableAllMonthsCheckBox(true)}}
                                        disabled={disableStartEndPicker}
                                    />
                                    </div>
                                </div>
                                <ErrorMessage name="reportStartDate" component="div" className="invalid-feedback" />
                            </FormGroup>

                            <FormGroup as={Col} controlId="reportEndDate">
                                <label htmlFor="reportEndDate" className="font-size-0-8">Report End Date</label>
                                <div style={pickerDivStyle}>
                                    <div style={formErrorStyle} className={formikProps.errors.reportEndDate && formikProps.touched.reportEndDate ? 'pickerError' : null}>
                                    <PbDatePicker 
                                        className="form-control font-size-0-8"
                                        id="reportEndDate"
                                        name='reportEndDate'
                                        placeholderText={"yyyy-mm-dd"}
                                        dateFormat='yyyy-MM-dd'
                                        value={formikProps.values.reportEndDate}
                                        errors={formikProps.errors.reportEndDate}
                                        touched={formikProps.touched.reportEndDate} 
                                        selected={formikProps.values.reportEndDate}
                                        onBlur={formikProps.handleBlur}
                                        onSelect={ async e => {setDisableAllMonthsCheckBox(true)}}
                                        disabled={disableStartEndPicker}
                                    />
                                    </div>
                                </div>
                                <ErrorMessage name="reportEndDate" component="div" className="invalid-feedback" />
                            </FormGroup>

                            <FormGroup as={Col}  controlId="checkBoxChecked">
                                <label  htmlFor="checkBoxChecked" className="font-size-0-8"> Search All Months</label>
                                <Field 
                                    type="checkbox" 
                                    id="checkBoxChecked"
                                    name="checkBoxChecked"
                                    className="form-control no-border"
                                    onBlur={ async e => {setDisableStartEndPicker(true)}}
                                    disabled={disableAllMonthsCheckBox}
                                />
                            </FormGroup>

                            <FormGroup as={Col} controlId="filterButtons">
                                <Row> &nbsp; </Row>
                                <Row>
                                    <Button type="submit" variant="outline-info" size="sm" className="mt-2 mr-2"> 
                                        Submit <FontAwesomeIcon icon={faPaperPlane} /> 
                                    </Button>
                                    <Button type="reset" variant="outline-danger" size="sm" className="mt-2 ml-2" onClick={(e) => handleFilterResetBtnClick(formikProps)}> 
                                        Reset <FontAwesomeIcon icon={faUndo} />
                                    </Button>
                                </Row>
                            </FormGroup>

                            <FormGroup as={Col} controlId="dummy1">
                                <Row> &nbsp; </Row>
                            </FormGroup>

                        </Row>
                            {/* <DisplayFormikState {...formikProps} /> */}
                        
                    </Form>
                )}
            </Formik>
        <Row>
        { !pbState.clearRecords && pbState.dbDataReceived  ? 
            <Alert variant={'info'} style={{ width: '100%'}}>
             Entries returned  { Object.keys(pbState.selectedViewPaneData).length}
            </Alert>
            : ""
        }
    </Row>
    </React.Fragment>
    );
}


export default SearchByMonth;