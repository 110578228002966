export const prodConfig = {
  Auth: {
    "region": "us-west-2",
    "userPoolId": "us-west-2_QyrVqGo5j",
    "userPoolWebClientId": "4alu41n56krccmoe5db2p7d5tj",
    "identityPoolId": "us-west-2:ebfd66f4-b65a-43b1-92cf-38724035b5ec",
    "mandatorySignIn": false,
    oauth: {
      "domain": "dv-live-pinboard3.auth.us-west-2.amazoncognito.com",
      "scope": [
        "openid"
      ],
      "redirectSignIn": "https://prod.pinboard.amazonavoc.com/",
      "redirectSignOut": "https://prod.pinboard.amazonavoc.com/signout/",
      "responseType": "code"
    }
  },
  API: {
    "endpoints": [{
      "name": "Gamma",
      "endpoint": "https://gamma.api.amazonavoc.com/",
      "region": "us-west-2"
    },
    {
      "name": "Prod",
      "endpoint": "https://prod.api.amazonavoc.com/",
      "region": "us-west-2"
    }
  ]
  }
}