import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import { Formik, Field, Form } from 'formik';
import { Button, Col, Container,  InputGroup, Row, Dropdown, ButtonGroup } from 'react-bootstrap';
import Table from 'react-bootstrap/Table'
import { faEdit, faTh, faFileAlt, faSearch, faUndo, faCopy, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactTooltip from "react-tooltip";
import { v4 as uuidv4 } from 'uuid';
// import { API, graphqlOperation } from 'aws-amplify';
// import { deleteData} from '../graphql/mutations';
import { deleteDBitem } from '../js/PbAPIUtils';
// import { DisplayFormikState } from './helper';

import { PbContext } from '../contexts/PbContextProvider';
import { updatedDBdataWithCalTimezone } from '../js/PbMiscUtils';
import { pbFormConfig, maintenanceFormat } from '../js/PbConstants';
import { DeleteFormModal } from './PbModal';
import { maintenanceFormInitialValues } from '../js/PbConstants';

const StyledContainer = styled(Container)`
  margin-top: 1.0rem;
  padding: 1%;
  font-size: 1.0vw;
  // margin-left: 6.0rem;
  border-radius: 0.5rem;
  width: 100%;
`;

const StyledBottomBorder = styled.div `
    margin: 1rem;
    width:100%; 
    border-bottom: 0.1rem solid #D3D3D3;
`;

const StyledPre = styled.pre`
  display: inline;
  margin: 0;
  font-size: 0.9vw;
  white-space:pre-line; 
  text-align: left;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
`

export const TextEventsData = (props) => {
  // 
  return (
    <div key={props.idx}> 
      <h5>{props.sortedViewPaneData[props.idx]["infrastructureCategory"]}</h5>
      {
        Object.values(maintenanceFormat).map( el => (
          el !== "infrastructureCategory" ? (
            el === 'affectedOrigins' ?
              <span key={`th2=$props.idx}-${el}`}>
                <b> {`${el.charAt(0).toUpperCase() + el.slice(1)}`.split(/(?=[A-Z])/).join(' ')} </b> : {viewOrigins(props.sortedViewPaneData[props.idx]["affectedOrigins"])}<br />
              </span>:
            el === 'maintenanceNotes' || el === 'propertiesImpacted' || el === 'maintenanceReason' || el === 'maintenanceImpact' ?  
              <StyledPre key={`${props.idx}-${el}`}>
                <b> {`${el.charAt(0).toUpperCase() + el.slice(1)}`.split(/(?=[A-Z])/).join(' ')} </b>  : {props.sortedViewPaneData[props.idx][el]}<br />
              </StyledPre> :
            <span key={`${props.idx}-${el}`}>
              <b> {`${el.charAt(0).toUpperCase() + el.slice(1)}`.split(/(?=[A-Z])/).join(' ')} </b>  : {props.sortedViewPaneData[props.idx][el]}<br />
            </span>
          )
           : undefined
        ))
      }
      <StyledBottomBorder></StyledBottomBorder>
    </div>
  )
}

const viewOrigins = (affectedOrigins) => {
  var i;
  let listOfOrigins = "";
  // console.log("affectedOrigins in viewOrigins function") //DEBUG AAM
  // console.dir(affectedOrigins) //DEBUG AAM
  
  if (affectedOrigins !== undefined) {
    for (i=0; i<affectedOrigins.length-1; i++) {
      listOfOrigins+=affectedOrigins[i] + ', ';
    }
    listOfOrigins+=affectedOrigins[affectedOrigins.length-1];
    return listOfOrigins;
  }
  else {
    return undefined;
  }
};


export const TextTableData = (props) => {
  const [ pbState, pbDispatch] = useContext(PbContext);

  const deleteTicketByIdx = async (ticketID) => {
    if (ticketID !== 'N/A') {
      try {
        console.log("Attempting to delete record");
        // const getDBdataReturn = await API.graphql(graphqlOperation(deleteData, {input: {id: ticketID}}));
        const getDBdataReturn = await deleteDBitem(ticketID);
        console.log("Deleted Record:", JSON.stringify(getDBdataReturn, null, 2));
        window.location.reload();
      }
      catch (err) {
        console.log(err)
      }
    }
  }


  const handleRecordDeleteBtnClick = (recordData) => {
    console.log(recordData); //DEBUG AAM
    pbDispatch({ type: 'SET_SHOW_DELETE_MODAL', showDeleteModal:  true });
    pbDispatch({ type: 'SET_DELETE_MODAL_DATA', deleteModalData:  recordData });
  }

  const handleCopyBtnClick = (formData) => {
    console.dir(formData);

    // fix for datepicker converting existing date to UTC because of ISO format.
    formData['startDate'] =  formData['startDate'].toLowerCase().includes("z") ? formData['startDate'].split('.')[0] : formData['startDate'];
    formData['endDate']   =  formData['endDate'].toLowerCase().includes("z") ? formData['endDate'].split('.')[0] : formData['endDate'];
    // formData['id'] = 'N/A';
    formData['id'] = uuidv4();
    formData['affectedOrigins']  = Array.isArray(formData['affectedOrigins'])
      ? formData['affectedOrigins'].map(x => { return({label: x, value: x})})
      : {label: "None", value: "None"}
    console.dir(formData); //DEBUG AAM

    console.log("after date conversion"); //DEBUG AAM
    console.dir(formData);

    pbDispatch({ type: 'SET_MFORM_VALUES', maintenanceFormValues: {...formData} });
    pbDispatch({type: 'SHOW_MAINTENANCE_FORM'});
    pbDispatch({type: 'HIDE_VIEW_REPORTS'});
    pbDispatch({type: 'HIDE_VIEW_CALENDAR'});
    pbDispatch({type: 'HIDE_REPORT_DATA'});
    pbDispatch({type: 'FROM_REPORT_VIEWER', fromReportViewer: true});
  }

  const handleEditBtnClick = (formData) => {

    console.log("before date conversion"); //DEBUG AAM
    console.dir(formData);

    // fix for datepicker converting existing date to UTC because of ISO format.
    formData['startDate'] =  formData['startDate'].toLowerCase().includes("z") ? formData['startDate'].split('.')[0] : formData['startDate'];
    formData['endDate']   =  formData['endDate'].toLowerCase().includes("z") ? formData['endDate'].split('.')[0] : formData['endDate'];
    formData['affectedOrigins']  = Array.isArray(formData['affectedOrigins'])
      ? formData['affectedOrigins'].map(x => { return({label: x, value: x})})
      : {label: "None", value: "None"}
    console.dir(formData); //DEBUG AAM

    pbDispatch({ type: 'SET_MFORM_VALUES', maintenanceFormValues: {...formData} });
    pbDispatch({type: 'SHOW_MAINTENANCE_FORM'});
    pbDispatch({type: 'HIDE_VIEW_REPORTS'});
    pbDispatch({type: 'HIDE_VIEW_CALENDAR'});
    pbDispatch({type: 'HIDE_REPORT_DATA'});
    pbDispatch({type: 'FROM_REPORT_VIEWER', fromReportViewer: true});
  }


  const handleStatusColor = (statusData) => {
    const statusColor = 
      {
        Cancelled: 'red',
        Emergency: 'purple',
        Pending: 'orange',
        Scheduled: 'green',
        Tentative: 'tan'
      }
    return <span style={{fontSize:'1.1em', fontWeight:'800', color:`${statusColor[statusData]}`}}>{statusData}  </span> 
  }

  const processToolTipMsg = (msg) => {
    msg = pbState.editRestrictions ? <span> {msg} <br /> access restriced </span> : msg;
    // console.log(msg); //DEBUG AAM
    return msg;
  }

  return (
    <div key={props.idx}> 
      <Table  striped borderless hover responsive size="sm" className="mb-5">
        <thead>
          <tr>  
            <td style={{ width: '20%' }}>  
            <Row>
              <Col md={2} className="mr-2" data-tip data-for='editButton'>
                  <Button
                    variant="warning"
                    size="sm"
                    className="mt-1"
                    disabled={pbState.editRestrictions ? true : false}
                    onClick={(e) => handleEditBtnClick(props.sortedViewPaneData[props.idx])} 
                  >
                    <FontAwesomeIcon style={{ color: 'white' }} icon={faEdit}/> 
                  </Button>
                  <ReactTooltip offset = {{'top': 30}} id="editButton" place="bottom" effect="solid">  {processToolTipMsg("Edit Record")} </ReactTooltip>
                </Col>
                <Col md={2} className="mr-2" data-tip data-for='copyButton'>
                  <Button
                    variant="warning"
                    size="sm"
                    className="mt-1"
                    disabled={pbState.editRestrictions ? true : false}
                    onClick={(e) => handleCopyBtnClick(props.sortedViewPaneData[props.idx])} 
                  >
                    <FontAwesomeIcon  style={{ color: 'white' }} icon={faCopy}/>
                  </Button>
                  <ReactTooltip offset = {{'top': 30}} id="copyButton" place="bottom" effect="solid">  {processToolTipMsg("Duplicate to New Entry")} </ReactTooltip>
                  &nbsp;&nbsp;
                </Col>
                <Col md={2} className="mr-2" data-tip data-for='deleteButton'>
                  <Button
                    variant="warning"
                    size="sm"
                    className="mt-1"
                    disabled={pbState.editRestrictions ? true : false}
                    onClick={(e) => handleRecordDeleteBtnClick(props.sortedViewPaneData[props.idx])} 
                  >
                    <FontAwesomeIcon  style={{ color: 'white' }} icon={faTrashAlt}/>
                  </Button>
                  <ReactTooltip offset = {{'top': 30}} id="deleteButton" place="bottom" effect="solid"> {processToolTipMsg("Delete This Record")} </ReactTooltip>
                </Col>
             </Row>
            </td>
            <td style={{width: '80%', fontSize:'1.4em', fontWeight:'bold', 'whiteSpace' : 'nowrap'}}>{props.sortedViewPaneData[props.idx]["infrastructureCategory"]}</td>
          </tr>
        </thead>
        <tbody>
          {
            Object.values(maintenanceFormat).map( el => (
              !["infrastructureCategory"].includes(el) ? 
              (
                <tr key={`tr-${props.idx}-${el}`}>
                  <th style={{ width: '20%' }} scope="row" key={`th1-${props.idx}-${el}`}> {`${el.charAt(0).toUpperCase() + el.slice(1)}`.split(/(?=[A-Z])/).join(' ')}  </th>
                  {
                    el === 'status' ?
                      <td style={{ width: '80%' }} key={`th2-${props.idx}-${el}`}> {handleStatusColor(props.sortedViewPaneData[props.idx]["status"])} </td> :
                    el === 'affectedOrigins' ?
                      <td style={{ width: '80%' }} key={`th2-$props.idx}-${el}`}>{viewOrigins(props.sortedViewPaneData[props.idx]["affectedOrigins"])}</td>:
                      el === 'maintenanceNotes' || el === 'propertiesImpacted' || el === 'maintenanceReason' || el === 'maintenanceImpact' ? 
                      <td style={{ width: '80%' }} key={`th2-${props.idx}-${el}`}> <StyledPre> {props.sortedViewPaneData[props.idx][el]} </StyledPre>  </td> :
                      <td style={{ width: '80%' }} key={`th2-${props.idx}-${el}`}> {props.sortedViewPaneData[props.idx][el]} </td>
                  }
                </tr>)
                : ""
              )
            ) 
          } 
        </tbody>
      </Table>
      { pbState.showDeleteModal &&  
        < DeleteFormModal 
          deleteFormFunction={deleteTicketByIdx}
          // deleteTicketID={props.sortedViewPaneData[props.idx].id}
          // maintenanceFormInitialValues={maintenanceFormInitialValues}
        /> 
      }
    </div>
  )
}


const ViewDBData = (props) => {
  const [ pbState, pbDispatch] = useContext(PbContext);
  // console.dir(props.filterInputData); //DEBUG  AAM
  // console.dir(pbState.selectedViewPaneData); //DEBUG  AAM
  // console.dir(props); //DEBUG  AAM

  let viewJSX = '';

  //Filter View Pane Data based on selected sort key
  const sortKey =  pbState.filterSortKey ?  pbState.filterSortKey : "startDate";
  const sortedViewPaneData = Object.values(pbState.selectedViewPaneData).sort( (a, b) => { 
      if ( a[sortKey] > b[sortKey]) return 1;
      if ( a[sortKey] < b[sortKey]) return -1;
      return 0;
  });

  if ( props.viewType === 'showText'){
    viewJSX = Object.keys(sortedViewPaneData).map( idx => { 
      // console.log("Inside showText & viewJSX parser"); //DEBUG AAM
      //Unfilterted View
      if (props.filterInputData === undefined || props.filterInputData === '') {
        // console.log("Executing Unfilterted View"); //DEBUG AAM
        return < TextEventsData key={idx}  idx={idx} sortedViewPaneData={sortedViewPaneData} />
      } //Filterted View
      else if (
        Object.values(sortedViewPaneData[idx]).filter( item => 
          item.toString().toLowerCase().includes(props.filterInputData.filterInput.trim().toLowerCase())
        ).length > 0) {
          return < TextEventsData  key={idx} idx={idx}  sortedViewPaneData={sortedViewPaneData} />
      }
    });
  }

  else if ( props.viewType === 'showTable') {
    viewJSX = Object.keys(sortedViewPaneData).map( idx => { 
      // console.log("Inside showTable & viewJSX parser"); //DEBUG AAM
      //Unfilterted View
      if (props.filterInputData === undefined || props.filterInputData === '') {
        // console.log("Executing Unfilterted View"); //DEBUG AAM
        return < TextTableData  key={idx} idx={idx}  sortedViewPaneData={sortedViewPaneData} />
      } //Filterted View
      else if (
        Object.values(sortedViewPaneData[idx]).filter( item => 
          item.toString().toLowerCase().includes(props.filterInputData.filterInput.trim().toLowerCase())
        ).length > 0) {
            return < TextTableData  key={idx} idx={idx}  sortedViewPaneData={sortedViewPaneData} />
      }
    });
  }

  else {
    viewJSX =  <div></div>
  }
  
  // console.log(props);  //DEBUG AAM
  // console.log("showing viewJSX"); //DEBUG AAM
  // console.dir(viewJSX); //DEBUG AAM
  return <React.Fragment> {viewJSX} </React.Fragment>
}


export default function PbReportDataViewer(props) {
  const [ textView, setTextView ] = useState(false);
  const [ pbState, pbDispatch] = useContext(PbContext);
  const [ filterInputData, setFilterInputData] = useState();

  useEffect( () => {
    pbDispatch({ type: 'HIDE_MAINTENANCE_FORM'});
    console.dir(pbState.selectedViewPaneData); //check if data is loaded when page loads.
  },[ ]);

  const handleFilterTzBtnClick = (tz, pbState, pbDispatch) => {
    console.log("You clicked Filter TimeZone button"); //DEBUG AAM
    pbDispatch({ type: 'SET_TIMEZONE', timeZone: tz });
    pbDispatch({ type: 'SET_SELECTED_VIEWPANE_DATA', selectedViewPaneData: {...updatedDBdataWithCalTimezone(pbState.selectedViewPaneData, tz)} });
    console.dir(tz);
  };

  const handleFilterSortByBtnClick = ( sortKey, pbState, pbDispatch) => {
    console.log("Selected sort key: " + sortKey );
    pbDispatch({ type: 'SET_FILTER_SORT_KEY', filterSortKey: sortKey });
    console.log("Modified sort key: " + pbState.filterSortKey);
  }
  
  
  
  const handleFormSubmit = (values, actions) => {
    actions.setSubmitting(false);
    // actions.resetForm();
    setFilterInputData(values);
    console.log(JSON.stringify(values, null, 2));
  }
  return ( 
    <StyledContainer>
      <Row className='border-bottom border-secondary m-1 p-1'>
        <Col md="3">
          <Button variant="secondary" size="md" className="m-1 p-1" onClick={(e) =>  {setTextView(false)}} >
            <FontAwesomeIcon icon={faTh}/> &nbsp;Table View  
          </Button>
          <Button variant="secondary" size="md" className="m-1 p-1" onClick={(e) =>  {setTextView(true)}} >
            <FontAwesomeIcon icon={faFileAlt}/> &nbsp;Text View  
          </Button>
        </Col>
        <Col  md="5">
          <Formik
              initialValues={{filterInput: ''}}
              onSubmit={handleFormSubmit}
          > 
            {(formikProps) => ( 
              <Form>
                  <Row>
                      <Col md="12">
                        <InputGroup  className='mb-2'>
                          <Field 
                              name="filterInput"
                              type="text" 
                              className={'form-control'} 
                              placeholder="Filter for any value from the report"
                          />
                          <InputGroup.Prepend>
                            <Button variant="secondary" size="sm" type="submit" data-tip data-for='searchButton' className="mr-2 rounded">
                              <FontAwesomeIcon icon={faSearch} /> 
                            </Button> 
                            <ReactTooltip id="searchButton" place="bottom" effect="solid">  Search Records    </ReactTooltip>
                            <Button variant="secondary" size="sm" type="reset" data-tip data-for='undoButton' className="rounded" onClick={ () => setFilterInputData(undefined)}
                              ><FontAwesomeIcon icon={faUndo}  />
                            </Button>
                            <ReactTooltip id="undoButton" place="bottom" effect="solid">  Reset Search   </ReactTooltip>
                          </InputGroup.Prepend>
                        </InputGroup>
                      </Col>
                  </Row>
              </Form>
            )}
          </Formik>
        </Col>
        <Col md={2} className=''>
          <Dropdown 
            as={ButtonGroup}
            title="timeZones"
            id="menubar-timezone-dropdown"
            onSelect={(tz ) => handleFilterTzBtnClick( tz, pbState, pbDispatch)}
          > 
            <Button variant="secondary" size="sm" style={{ pointerEvents: 'none' }}> {"Time Zone"} </Button>
            <Dropdown.Toggle split variant="secondary" id="dropdown-split-basic" data-tip data-for='tzMenuDropdown'/>
            {/* <ReactTooltip id='tzMenuDropdown'> Timezone Selection Dropdown </ReactTooltip> */}
            <Dropdown.Menu>
              {   
                  pbFormConfig.timeZone.options.map((item, idx) => (
                    <Dropdown.Item key={idx} eventKey={item} >{item}</Dropdown.Item>
                  ))
              }
            </Dropdown.Menu>
          </Dropdown>
        </Col>
        <Col md={2} className=''>
          <Dropdown 
            as={ButtonGroup}
            title="sortByDropDown"
            id="sortByDropDown"
            onSelect={(sortKey ) => handleFilterSortByBtnClick( sortKey, pbState, pbDispatch)}
          > 
            <Button variant="secondary" size="sm" style={{ pointerEvents: 'none' }}> {"Sort By"} </Button>
            <Dropdown.Toggle split variant="secondary" id="dropdown-split-basic" data-tip data-for='sortByDropDown'/>
            <Dropdown.Menu>
              {  
                ['id', 'infrastructureCategory', 'maintenanceTicket', 'vendor', 'startDate', 'endDate', 'status']
                  .map((item, idx) => (
                  <Dropdown.Item key={`sortByDropDown-${idx}`} eventKey={item} >{item}</Dropdown.Item>
                ))
              }
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row> 
      <Container>
        { 
          pbState.showDBdata && textView 
          ?  < ViewDBData filterInputData={filterInputData} viewType={'showText'} />  
          :  < ViewDBData filterInputData={filterInputData}  viewType={'showTable'} /> 
        }
      </Container>  
    </StyledContainer>
  ) 
}




