import React, {useContext} from 'react';
import { Card,  Container,Row, Col } from 'react-bootstrap';
import styled from 'styled-components';

import SearchByMonth from './Subreport';
import PbReportDataViewer from './PbReportDataViewer';
import { PbContext } from '../contexts/PbContextProvider';
import { PbSpinner } from './PbSpinner';


// Local Styled components
const StyledReportCard = styled(Card)`
  background-color: white;
  padding: 1%;
  font-size: 1.2vw;
  border: 1px solid white;
  border-radius: 0.5rem;
  width: 100%;
`

const ViewReports = () => {
    const [ pbState, pbDispatch] = useContext(PbContext);
      console.dir(pbState); //DEBUG AAM

    return(
        <StyledReportCard>
            <Container>
                <Row className='ml-2'>
                    <Col>
                        {
                            pbState.searchByMonth ?  <SearchByMonth /> : null
                        }
                    </Col>
                </Row>
                { !pbState.dbDataReceived ? 
                    <Row>
                        <Col className={"col-12 border "} >
                            < PbSpinner />
                        </Col>
                    </Row>
                    : 
                    !pbState.clearRecords
                    ?
                    <Row>
                        {
                            pbState.showReportData ?  <PbReportDataViewer /> : ""
                        }
                    </Row>
                    :
                    ""
                }
            </Container>
        </StyledReportCard>
    );
}


export default ViewReports;