import React, { useContext, useEffect,useState, useRef } from 'react';
import styled from 'styled-components';
import { Card, Container,Col, Row, Button } from 'react-bootstrap';
import { faPaperPlane, faUndo, faTrashAlt, faWindowRestore, faInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { DateTime } from "luxon";
import * as Yup from 'yup';
import ReactTooltip from "react-tooltip";
import Alert from 'react-bootstrap/Alert';

import PbDatePicker from './DatePicker';
import { pbFormConfig, formErrorStyle } from '../js/PbConstants';
import { PbContext } from '../contexts/PbContextProvider';
// import { DisplayFormikState } from './helper';
import { maintenanceFormInitialValues, timeZoneOptions } from '../js/PbConstants';
import { createDBitem, updateDBitem, deleteDBitem, emosDBitem} from '../js/PbAPIUtils';
import { getTzAdjustedDate } from '../js/PbMiscUtils';
// import { createLocalDBitem, deleteLocalDBitem, updateLocalDBitem } from '../js/localAPIUtils';
import { DeleteFormModal, InfoModal } from './PbModal';
import MultiSelect from './MultiSelect';
import { S3DataLoader } from './S3DataLoader';

const StyledEntryCard = styled(Card)`
  background-color: white;
  padding: 1%;
  font-size: 1.0vw;
  margin-left: 0.5rem;
  border: 1px solid white;
  border-radius: 0.5rem;
  width: 100%;
`

const StyledFormHeader = styled.h5 `
  justify-content: center;
  align-items: center;
  margin: 0 auto; 
  text-align: center;
  font-size: 1.6vw;
  font-weight: bold;
  letter-spacing: 0.2rem;
  margin-bottom: 12px;
  color: #e47911; /* Amazon Orange */
`;


const StyledBottomBorder = styled.div `
    margin-bottom: 1.5rem;
    width:100%; 
    border-bottom: 0.1rem solid #D3D3D3;
`;

const MaintenanceForm = (props) => {
  const [ pbState, pbDispatch] = useContext(PbContext);
  const [ isLoading, setLoading] = useState(false);
  const [ isAlertError, setAlertError] = useState(false);
  const [ isAlertSuccess, setAlertSuccess] = useState(false);
  const [ isAlertException, setAlertException] = useState(false);
  
  const maintenanceFormValidationSchema= Yup.object().shape({
    id: Yup.string().required('required field'),
    lastUpdatedBy: Yup.string().nullable(true),
    infrastructureCategory: Yup.string().required('required field'),
    maintenanceTicket:  Yup.string().required('required field'),
    vendor:  Yup.string().required('required field'),
    startDate:  Yup.date().required('required field'),
    timeZone: Yup.string().required('required field'),
    endDate: Yup.date()
    .min(
      Yup.ref('startDate'),
      "end date can't be before start date"
    )
    .required('required field'),
    status:  Yup.string().required('required field'),
    // affectedOrigins: Yup.array().of(Yup.string()).required('required field'),
    // affectedOrigins: Yup.object().required('required field'),
    overlapsWith:  Yup.string().required('required field'),
    opsPlan:  Yup.string().required('required field'),
    infrastructureComponent: Yup.string().required('required field'),
    propertiesImpacted:  Yup.string().required('required field'),
    maintenanceReason:  Yup.string().required('required field'),
    maintenanceImpact:  Yup.string().required('required field'),
    maintenanceNotes:  Yup.string().required('required field'),
  });

  const handleMaintenanceFormSubmit = (values, actions) => {
    console.log("handleMaintenanceFormSubmit values=> "); //DEBUG AAM
    console.dir(values); //DEBUG AAM
    values.lastUpdatedBy=pbState.lastUpdatedBy;
    values.pending = false;
    
    actions.setSubmitting(false);
    actions.resetForm();
    pbDispatch({ type: 'SET_MFORM_VALUES', maintenanceFormValues: {...values} });
    pbDispatch({ type: 'FROM_REPORT_VIEWER', fromReportViewer: false});
    pbDispatch({ type: 'DB_DATA_RECEIVED', dbDataReceived: false });
    pbDispatch({ type: 'SHOW_S3_DATA_DIV', showS3DataDiv: false });
    pbDispatch({ type: 'S3_WINDOW_OPEN_STATUS', s3DataWindowDivOpen: false });
    pbDispatch({ type: 'FROM_PENDING_PINS', fromPendingPins: false });
    processFormSubmit(values);
  }

  const adjustOutGoingDate = (dbDate, dbTimeZone) => {
    /* Logic:
    Get ISO string from form data , then Get timezone from form 
    Convert formDate to timezone as per form timezone
    */
   console.log("In adjustOutGoingDate, dbDate=>" + dbDate); //DEBUG AAM
   console.log(typeof(dbDate))
   console.log("timezone", dbTimeZone);
   if ( typeof(dbDate) != "string" && dbTimeZone != "GMT"){
    let tempDate = new Date(dbDate).toISOString();
    console.log("tempDate=>" + tempDate ); //DEBUG AAM
    let newDate = DateTime.fromISO(tempDate).setZone(timeZoneOptions[dbTimeZone]).toISO();
    console.log("newDate=>" + newDate ); //DEBUG AAM
    return newDate.toLowerCase().includes("z") ? newDate.split('.')[0] : newDate;
   }
   else if(typeof(dbDate) != "string" && (dbTimeZone === "GMT")){
     console.log(dbDate)
     let new_date = new Date(dbDate);
     console.log(new_date)
     let offset = new_date.getTimezoneOffset()/60;
     console.log(offset)
     if(offset > 0){
       let temp_date = new_date.setHours(new_date.getHours()-offset)
     }
     else{
       let temp_date = new_date.setHours(new_date.getHours()+offset)
     }
     console.log("before converting to iso",new_date)
     dbDate = new_date.toISOString();
     console.log("converted", dbDate)
     return dbDate;
   }
   else {
     console.log("This conversion is handled in emosbuttonclick", dbDate)
     return dbDate
   }
  }

  const processFormSubmit = async ( dbJson) => {
    dbJson.startDate = adjustOutGoingDate(dbJson.startDate, dbJson.timeZone);
    dbJson.endDate = adjustOutGoingDate(dbJson.endDate, dbJson.timeZone);
    dbJson.affectedOrigins = dbJson.affectedOrigins !== undefined && dbJson.affectedOrigins.length > 0
      ? dbJson.affectedOrigins.map(x=>x.label)
      : ["None"];
    dbJson.lastUpdatedBy = pbState.lastUpdatedBy;
    console.log(dbJson); //DEBUG AAM

    if (dbJson["id"] !== "N/A" &&  dbJson["id"] !== null &&  dbJson["id"] !== undefined){
      console.log(`updateDBitem | dbJson["id"] = ${dbJson["id"]}`); //DEBUG AAM
      try {
        const apiReturnData = await updateDBitem(dbJson); 
        pbDispatch({ type: 'SET_MFORM_VALUES', maintenanceFormValues: {...maintenanceFormInitialValues} });
        pbDispatch({ type: 'FROM_REPORT_VIEWER', fromReportViewer: false});
        pbDispatch({ type: 'SET_SHOW_MODAL', showModal:  true });
        console.dir(apiReturnData) //DEBUG AAM
        pbDispatch({ 
          type: 'SET_MODAL_VALUES',
          modalHeader: 'Success :',
          modalBody: `Updated Record with ID  \n  ${apiReturnData.data.Attributes.id}`
        });
        console.log("Updated Existing Record:", JSON.stringify(apiReturnData, null, 2));//DEBUG AAM
      } 
      catch (updateErr) {
        console.log(updateErr);
      }
    } 
    else if (dbJson["id"] === 'N/A') {
      console.log(`createDBitem | dbJson["id"] = ${dbJson["id"]}`); //DEBUG AAM
      console.dir(dbJson); //DEBUG AAM
      try {
        const apiReturnData = await createDBitem(dbJson); 
        console.log(apiReturnData); //DEBUG AAM

        pbDispatch({ type: 'SET_MFORM_VALUES', maintenanceFormValues: {...maintenanceFormInitialValues} });
        pbDispatch({type: 'FROM_REPORT_VIEWER', fromReportViewer: false});
        console.dir(apiReturnData) //DEBUG AAM
        pbDispatch({ type: 'SET_SHOW_MODAL', showModal:  true });
        pbDispatch({ 
          type: 'SET_MODAL_VALUES',
          modalHeader: 'Success :',
          modalBody: `Created Record with ID \n  ${apiReturnData['data']['sentData']['id']}`
        });
        console.log("Created New Record:", JSON.stringify(apiReturnData['sentData'], null, 2));
      } 
      catch (uploadErr) {
        console.log(uploadErr);
      }
    }
  }

  const handleResetBtnClick = (formikProps) => {
    formikProps.isSubmitting = false;
    pbDispatch({ type: 'SET_MFORM_VALUES', maintenanceFormValues: {...maintenanceFormInitialValues} });
    pbDispatch({type: 'FROM_REPORT_VIEWER', fromReportViewer: false});
  }

  const deleteTicketByID = async (ticketID) => {
    if (ticketID !== 'N/A') {
      try {
        console.log("Attempting to delete record");
        const getDBdataReturn = await deleteDBitem(ticketID); 
        console.log("Deleted Record:", JSON.stringify(getDBdataReturn, null, 2));
        pbDispatch({ type: 'SET_MFORM_VALUES', maintenanceFormValues: {...maintenanceFormInitialValues} });
        pbDispatch({type: 'FROM_REPORT_VIEWER', fromReportViewer: false});
        pbDispatch({ type: 'SET_DELETE_MAINTENANCE_REQUEST', deleteMaintenanceRequest:  false });
      }
      catch (err) {
        console.log(err)
      }
    }
  }

  const handleDeleteBtnClick = async (formikProps) => {
    console.log("deleting ticket"); //DEBUG AAM
    console.dir(formikProps); //DEBUG AAM
    pbDispatch({ type: 'SET_SHOW_DELETE_MODAL', showDeleteModal:  true });
  }
  
  const handleEmosBtnClick = async (values) => {
    setLoading(true);
    console.log("emosing things");
    if (values["infrastructureCategory"] === "Live Event" && values["status"] !== "Cancelled"){
      try {
        if((typeof(values.startDate) === "string")&&(typeof(values.endDate) === "string")){
          console.log(values.startDate);
          for(const k in pbState.dbData){
            if(pbState.dbData[k]["id"] === values["id"]){
              values.startDate = getTzAdjustedDate(pbState.dbData[k]["startDate"],pbState.dbData[k]["timeZone"], values.timeZone);
              values.endDate = getTzAdjustedDate(pbState.dbData[k]["endDate"],pbState.dbData[k]["timeZone"], values.timeZone);
            }
          }
        }
        else if((typeof(values.startDate) != "string")&&(typeof(values.endDate) === "string")){
          console.log(values.startDate);
          values.startDate = adjustOutGoingDate(values.startDate, values.timeZone);
          for(const k in pbState.dbData){
            if(pbState.dbData[k]["id"] === values["id"]){
              values.endDate = getTzAdjustedDate(pbState.dbData[k]["endDate"],pbState.dbData[k]["timeZone"], values.timeZone);
            }
          }
        }
        else if((typeof(values.startDate) === "string")&&(typeof(values.endDate) != "string")){
          console.log(values.startDate);
          values.endDate = adjustOutGoingDate(values.endDate, values.timeZone);
          for(const k in pbState.dbData){
            if(pbState.dbData[k]["id"] === values["id"]){
              values.startDate = getTzAdjustedDate(pbState.dbData[k]["startDate"],pbState.dbData[k]["timeZone"], values.timeZone);
            }
          }
        }
        else{
          values.startDate = adjustOutGoingDate(values.startDate, values.timeZone);
          values.endDate = adjustOutGoingDate(values.endDate, values.timeZone);
        }
      }
      catch (err) {
        console.log(err);
        setAlertException(true);
        setLoading(false);
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      }
      try {
        console.log("Updating Overlaps With & Ops Plan");
        console.dir(values);
        const getDBdataReturn = await emosDBitem(values); 
        console.log("Updated Record:", JSON.stringify(getDBdataReturn, null, 2));
        values.lastUpdatedBy = pbState.lastUpdatedBy;
        values.overlapsWith = getDBdataReturn[0] !== undefined ? getDBdataReturn[0] : values.overlapsWith;
        values.opsPlan = getDBdataReturn[1] !== undefined ? getDBdataReturn[1] : values.opsPlan;
        setAlertSuccess(true);
        setLoading(false);
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      }
      catch (err) {
        console.log(err);
        setAlertException(true);
        setLoading(false);
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      }
    }
    else {
      console.log("Not a Live Event or maintenance cancelled");
      setAlertError(true);
      setLoading(false);
      window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }
  };

  const handleS3DataWindowOpen = () => {
    console.log( " opening Original Email Body ");// DEBUG AAM
    pbDispatch({ type: 'SHOW_S3_DATA_DIV', showS3DataDiv: true });
    pbDispatch({ type: 'S3_WINDOW_OPEN_STATUS', s3DataWindowDivOpen: true });
    console.log(pbState);// DEBUG AAM
  }

  useEffect( () => {
      pbDispatch({ type: 'SET_SELECTED_VIEWPANE_DATA', selectedViewPaneData: {} });
      pbDispatch({ type: 'HIDE_VIEW_CALENDAR' }); 
      pbDispatch({ type: 'HIDE_REPORT_DATA' });
      pbDispatch({ type: 'HIDE_VIEW_REPORTS' }); 
      pbDispatch({ type: 'HIDE_DAY_CALENDAR' }); 
  },[]);


  return (
    <React.Fragment>
      <Alert show={isAlertError} variant="danger" onClose={() => setAlertError(false)} dismissible>This maintenance does not affect a Live Event or has been Cancelled.</Alert>
      <Alert show={isAlertSuccess} variant="success" onClose={() => setAlertSuccess(false)} dismissible>Event overlap check complete</Alert>
      <Alert show={isAlertException} variant="danger" onClose={() => setAlertException(false)} dismissible>Error trying to detect overlaps. See console logs.</Alert>
      <Row>
        <Col>
      <Formik
        enableReinitialize={true}
        validateOnMount={true}
        // initialValues={pbState.fromReportViewer ?  pbState.maintenanceFormValues : {...pbState.maintenanceFormValues, id: 'N/A', lastUpdatedBy: 'N/A'} }
        initialValues={pbState.fromReportViewer ?  pbState.maintenanceFormValues : {...pbState.maintenanceFormValues, id: 'N/A'} }
        validationSchema={maintenanceFormValidationSchema}
        onSubmit={handleMaintenanceFormSubmit}
      >
      {(formikProps) => ( 
          <StyledEntryCard>
            <Container >
            {/* <StyledFormHeader>Maintenance Entry Input Form</StyledFormHeader>
              <Row><StyledBottomBorder> </StyledBottomBorder></Row> */}
               <Row>
                  <Col>
                    <StyledFormHeader>Maintenance Entry Input Form </StyledFormHeader>
                  </Col>
                  {/* { pbState.showS3DataDiv &&  !pbState.s3DataWindowDivOpen && pbState.fromPendingPins && */}
                  {   !pbState.s3DataWindowDivOpen && pbState.fromPendingPins &&
                    <Col md="3">
                    <Button variant="outline-danger" size="sm" className="mb-2" data-tip data-for="openS3DivButton" onClick={() => handleS3DataWindowOpen()}>
                      Open <FontAwesomeIcon icon={faWindowRestore} />
                      <ReactTooltip offset = {{'top': 4}} id="openS3DivButton" place="bottom" effect="solid">  {"Open S3 Div"} </ReactTooltip>
                    </Button>
                  </Col>
                  }
                  
                </Row>
                <Row><StyledBottomBorder> </StyledBottomBorder></Row>
                <Form autoComplete="off">

                  {/* Row -1 */}
                  <Row className="mb-3 mr-sm-2" >
                    <Col md="3" className="text-right form-text" >
                      <label htmlFor="lastUpdatedBy">Last Updated By</label>
                    </Col>
                    <Col md="3">
                      <Field 
                        name="lastUpdatedBy"
                        // value={pbState.lastUpdatedBy}
                        value={formikProps.values.lastUpdatedBy === undefined ? pbState.lastUpdatedBy : formikProps.values.lastUpdatedBy}
                        type="text" 
                        readOnly
                        // style={{ fontSize: "0.6vw"}}
                        className={ 'form-text form-control' + (formikProps.errors.lastUpdatedBy && formikProps.touched.lastUpdatedBy ? ' is-invalid' : '')} 
                      />
                        <ErrorMessage name="lastUpdatedBy" component="div" className="invalid-feedback" />
                    </Col>
                  </Row>

                  {/* Row -2 */}
                  <Row  className="mb-3 mr-sm-2">
                    <Col md="3" className="text-right form-text" >
                        <label  htmlFor="id">Ticket Id</label>
                    </Col>
                    <Col md="3">
                        <Field
                            style={{ textSize: "4px"}}
                            name="id"
                            type="text" 
                            readOnly
                            className={'form-text form-control' + (formikProps.errors.id && formikProps.touched.id ? ' is-invalid' : '')} 
                        />
                        <ErrorMessage name="id" component="div" className="invalid-feedback" />
                    </Col>
                    <Col md="2" className="text-right form-text" >
                      <label htmlFor="infrastructureCategory">Infrastructure Category</label>
                    </Col>
                    <Col md="3">
                      <Field 
                          name="infrastructureCategory"
                          as="select"
                          className={'form-control form-text' + (formikProps.errors.infrastructureCategory && formikProps.touched.infrastructureCategory ? ' is-invalid' : '')} 
                      >
                          <option value=''>Choose...</option>
                          {pbFormConfig['infrastructureCategory'].options.map((op, idx) => (
                              <option key={`infrastructureCategory-idx-${op}`}   id={`idx-${op}`} >{op}</option>
                          ))}
                      </Field>
                      <ErrorMessage name="infrastructureCategory" component="div" className="invalid-feedback" />
                    </Col>
                  </Row>

                  {/* Row -2 */}
                  <Row className="mb-3 mr-sm-2">
                    <Col md="3" className="text-right form-text" >
                        <label htmlFor="maintenanceTicket">Maintenance Ticket</label>
                    </Col>
                    <Col md="3">
                        <Field 
                            name="maintenanceTicket"
                            type="text" 
                            className={'form-text form-control' + (formikProps.errors.maintenanceTicket && formikProps.touched.maintenanceTicket ? ' is-invalid' : '')} 
                        />
                        <ErrorMessage name="maintenanceTicket" component="div" className="invalid-feedback" />
                    </Col>
                    <Col md="2" className="text-right form-text" >
                      <label htmlFor="vendor">Vendor</label>
                    </Col>
                    <Col md="3">
                      <Field 
                          name="vendor"
                          as="select"
                          className={'form-control form-text' + (formikProps.errors.vendor && formikProps.touched.vendor ? ' is-invalid' : '')} 
                      >
                          <option value=''>Choose...</option>
                          {pbFormConfig['vendor'].options.map((op, idx) => (
                              <option key={`vendor-idx-${op}`}   id={`idx-${op}`} >{op}</option>
                          ))}
                      </Field>
                      <ErrorMessage name="vendor" component="div" className="invalid-feedback" />
                    </Col>
                  </Row>

                  {/* Row -3 */}
                  <Row className="mb-3 mr-sm-2">
                    <Col md="3" className="text-right form-text" >
                        <label htmlFor="startDate">Start Date & Time</label>
                    </Col>
                    <Col md="3">
                      <div style={formErrorStyle} className={'form-text' + (formikProps.errors.startDate && formikProps.touched.startDate ? 'pickerError' : '')}>
                        <PbDatePicker
                          // className='form-control'
                          id="startDate"
                          name='startDate'
                          placeholderText={'yyyy-MM-dd HH:mm'}
                          showTimeSelect
                          timeIntervals={5}
                          dateFormat='yyyy-MM-dd HH:mm'
                          timeFormat='HH:mm'
                          value={formikProps.values.startDate}
                          errors={formikProps.errors.startDate}
                          touched={formikProps.touched.startDate} 
                          selected={formikProps.values.startDate}
                          onBlur={formikProps.handleBlur}
                        />
                      </div>
                      <ErrorMessage name="startDate" component="div" className="invalid-feedback" />
                    </Col>
                    <Col md="2" className="text-right form-text" >
                      <label htmlFor="timeZone">Time Zone</label>
                    </Col>
                    <Col md="3">
                      <Field 
                          name="timeZone"
                          as="select"
                          className={'form-control form-text' + (formikProps.errors.timeZone && formikProps.touched.timeZone ? ' is-invalid' : '')} 
                      >
                          <option value=''>Choose...</option>
                          {pbFormConfig['timeZone'].options.map((op, idx) => (
                              <option key={`timeZone-idx-${op}`}   id={`idx-${op}`} >{op}</option>
                          ))}
                      </Field>
                      <ErrorMessage name="timeZone" component="div" className="invalid-feedback" />
                    </Col>
                  </Row>

                  {/* Row -4 */}
                  <Row className="mb-3 mr-sm-2">
                    <Col md="3" className="text-right form-text" >
                        <label htmlFor="endDate">End Date & TIme</label>
                    </Col>
                    <Col md="3">
                            <div style={formErrorStyle} className={'form-text' + (formikProps.errors.endDate && formikProps.touched.endDate ? 'pickerError' : '')}>
                              <PbDatePicker
                                id="endDate"
                                name='endDate'
                                timeCaption="Time"
                                placeholderText={'yyyy-MM-dd HH:mm'}
                                showTimeSelect
                                timeIntervals={5}
                                dateFormat='yyyy-MM-dd HH:mm'
                                timeFormat='HH:mm'
                                value={formikProps.values.endDate}
                                errors={formikProps.errors.endDate}
                                touched={formikProps.touched.endDate} 
                                selected={formikProps.values.endDate}
                                onBlur={formikProps.handleBlur}
                              />
                            </div>
                          <ErrorMessage name="endDate" component="div" className="invalid-feedback" />
                        </Col>
                    <Col md="2" className="text-right form-text" >
                      <label htmlFor="status">Status</label>
                    </Col>
                    <Col md="3">
                      <Field 
                          name="status"
                          as="select"
                          className={'form-control form-text' + (formikProps.errors.status && formikProps.touched.status ? ' is-invalid' : '')} 
                      >
                          <option value=''>Choose...</option>
                          {pbFormConfig['status'].options.map((op, idx) => (
                              <option key={`status-idx-${op}`}   id={`idx-${op}`} >{op}</option>
                          ))}
                      </Field>
                      <ErrorMessage name="status" component="div" className="invalid-feedback" />
                    </Col>
                  </Row>

                  {/* Row - 5 */}
                  <Row className="mb-3 mr-sm-2">
                      <Col md="3" className="text-right form-text" >
                        <label htmlFor="affectedOrigins">Affected Origins</label>
                      </Col>
                      <Col md="8">
                      <div style={formErrorStyle} className={'form-text' + (formikProps.errors.affectedOrigins && formikProps.touched.affectedOrigins ? 'is-invalid' : '')}>
                        <MultiSelect 
                            name={'affectedOrigins'}
                            options={pbFormConfig['affectedOrigins'].options}
                          />
                        <ErrorMessage name="affectedOrigins" component="div" className="invalid-feedback" />
                        </div>
                      </Col>
                    </Row>

                  {/* Row -6 */}
                  <Row className="mb-3 mr-sm-2">
                    <Col md="3" className="text-right form-text" >
                        <label htmlFor="overlapsWith">Overlaps With</label>
                    </Col>
                    <Col md="8">
                        <Field 
                            name="overlapsWith"
                            as="textarea" rows={2}
                            className={'form-control form-text' + (formikProps.errors.overlapsWith && formikProps.touched.overlapsWith ? ' is-invalid' : '')}
                        />
                        <ErrorMessage name="overlapsWith" component="div" className="invalid-feedback" />
                    </Col>
                  </Row>

                  {/* Row -7 */}
                  <Row className="mb-3 mr-sm-2">
                      <Col md="3" className="text-right form-text" >
                          <label htmlFor="opsPlan">Ops Plan</label>
                      </Col>
                      <Col md="8">
                          <Field 
                              name="opsPlan"
                              as="textarea" rows={2}
                              className={'form-control form-text' + (formikProps.errors.opsPlan && formikProps.touched.opsPlan ? ' is-invalid' : '')} 
                          />
                          <ErrorMessage name="opsPlan" component="div" className="invalid-feedback" />
                      </Col>
                    </Row>

                  {/* Row -8 */}
                  <Row className="mb-3 mr-sm-2">
                    <Col md="3" className="text-right form-text" >
                        <label htmlFor="infrastructureComponent">Infrastructure Component</label>
                    </Col>
                    <Col md="8">
                        <Field 
                            name="infrastructureComponent"
                            as="textarea" rows={2}
                            className={'form-control form-text' + (formikProps.errors.infrastructureComponent && formikProps.touched.infrastructureComponent ? ' is-invalid' : '')} 
                        />
                        <ErrorMessage name="infrastructureComponent" component="div" className="invalid-feedback" />
                    </Col>
                  </Row>

                  {/* Row -9 */}
                  <Row className="mb-3 mr-sm-2">
                    <Col md="3" className="text-right form-text" >
                        <label htmlFor="propertiesImpacted">Feeds Impacted</label>
                    </Col>
                    <Col md="8">
                        <Field 
                            name="propertiesImpacted"
                            as="textarea" rows={2} 
                            className={'form-control form-text' + (formikProps.errors.propertiesImpacted && formikProps.touched.propertiesImpacted ? ' is-invalid' : '')} 
                        />
                        <ErrorMessage name="propertiesImpacted" component="div" className="invalid-feedback" />
                    </Col>
                  </Row>

                  {/* Row -10 */}
                  <Row className="mb-3 mr-sm-2">
                    <Col md="3" className="text-right form-text" >
                        <label htmlFor="maintenanceReason">Maintenance Reason</label>
                    </Col>
                    <Col md="8">
                        <Field 
                            name="maintenanceReason"
                            as="textarea" rows={2}
                            className={'form-control form-text' + (formikProps.errors.maintenanceReason && formikProps.touched.maintenanceReason ? ' is-invalid' : '')} 
                        />
                        <ErrorMessage name="maintenanceReason" component="div" className="invalid-feedback" />
                    </Col>
                  </Row>

                  {/* Row -11 */}
                  <Row className="mb-3 mr-sm-2">
                    <Col md="3" className="text-right form-text" >
                        <label htmlFor="maintenanceImpact">Maintenance Impact</label>
                    </Col>
                    <Col md="8">
                        <Field 
                            name="maintenanceImpact"
                            as="textarea" rows={2}
                            className={'form-control form-text' + (formikProps.errors.maintenanceImpact && formikProps.touched.maintenanceImpact ? ' is-invalid' : '')} 
                        />
                        <ErrorMessage name="maintenanceImpact" component="div" className="invalid-feedback" />
                    </Col>
                  </Row>

                  {/* Row -12 */}
                  <Row className="mb-3 mr-sm-2">
                    <Col md="3" className="text-right form-text" >
                        <label htmlFor="maintenanceNotes">Maintenance Notes</label>
                    </Col>
                    <Col md="8">
                        <Field 
                            name="maintenanceNotes"
                            as="textarea" rows={2}
                            className={'form-control form-text' + (formikProps.errors.maintenanceNotes && formikProps.touched.maintenanceNotes ? ' is-invalid' : '')} 
                        />
                        <ErrorMessage name="maintenanceNotes" component="div" className="invalid-feedback" />
                    </Col>
                  </Row>

                  {/* Row -13 */}
                  <Row className="mb-3 mr-sm-2">
                    <Col md="2"></Col>
                      <Col md="2">
                          <Button type="submit" variant="outline-info" size="md" className="mt-2"> 
                            Submit <FontAwesomeIcon icon={faPaperPlane} />
                          </Button>
                      </Col>
                      <Col md="2">
                          <Button variant="outline-secondary" size="md" className="mt-2" disabled={isLoading} onClick={!isLoading ?
                          () =>
                          [formikProps.setTouched({
                          propertiesImpacted: true,
                          startDate: true,
                          endDate: true,
                          timeZone: true,
                          infrastructureCategory: true
                          }),
                          handleEmosBtnClick(formikProps.values)]
                          : null}>
                            <FontAwesomeIcon icon={faInfo} />
                            {isLoading ? 'Emosing…' : 'Emos'}
                          </Button>
                      </Col>
                      <Col md="2">
                          <Button type="reset" variant="outline-warning" size="md" className="mt-2"  onClick={() => handleResetBtnClick(formikProps)}> 
                            Reset <FontAwesomeIcon icon={faUndo} />
                          </Button>
                      </Col>
                      <Col md="2">
                          <Button variant="outline-danger" size="md" className="mt-2" onClick={() => handleDeleteBtnClick(formikProps)}>
                            Delete <FontAwesomeIcon icon={faTrashAlt} />
                          </Button>
                      </Col>
                  </Row>
                  <Row> { pbState.showDeleteModal &&  
                    < DeleteFormModal 
                      deleteFormFunction={deleteTicketByID}
                      deleteTicketID={formikProps.values["id"]}
                      maintenanceFormInitialValues={maintenanceFormInitialValues}
                    /> }
                  </Row>

                  {/* Un Comment following to debug formik state */}
                  {/* <DisplayFormikState {...formikProps} /> */}
                  { console.log(formikProps) }
              </Form>
            </Container>
        </StyledEntryCard>
      )}
      </Formik>
      </Col>
      {console.log(pbState)}
      { pbState.showS3DataDiv &&
        <Col>
          <StyledEntryCard>
              <S3DataLoader />
          </StyledEntryCard>
        </Col>
      }
    </Row>
    </React.Fragment>

  );
}

export default MaintenanceForm;


