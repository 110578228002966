// 
//Maintenance Form Elements
const pbFormConfig = {
  id: {
    type: "text",
    placeHolder: "Ticket ID",
    options: undefined,
  },
  lastUpdatedBy: {
    type: "text",
    placeHolder: "Last Updated By",
    options: undefined,
  },
  infrastructureCategory: {
    type: "dropDown",
    placeHolder: "Select Infrastructure Category",
    options: ["Live Event", "Not Live Event"],
  },
  affectedOrigins: {
    type: "dropDown",
    placeholder: "Select Affected Origins",
    options: [
      { label: "iad-1 - us-east-1", value: "iad-1" },
      { label: "iad-2 - us-east-1", value: "iad-2" },
      { label: "pdx-1 - us-west-2", value: "pdx-1" },
      { label: "pdx-2 - us-west-2", value: "pdx-2" },
      { label: "sfo-1 - us-west-1", value: "sfo-1" },
      { label: "sfo-2 - us-west-1", value: "sfo-2" },
      { label: "dub-1 - eu-west-1", value: "dub-1" },
      { label: "dub-2 - eu-west-1", value: "dub-2" },
      { label: "lhr-1 - eu-west-2", value: "lhr-1" },
      { label: "lhr-2 - eu-west-2", value: "lhr-2" },
      { label: "fra-1 - eu-central-1", value: "fra-1" },
      { label: "fra-2 - eu-central-1", value: "fra-2" },
      { label: "gru-1 - sa-east-1", value: "gru-1" },
      { label: "gru-2 - sa-east-1", value: "gru-2" },
      { label: "bom-1 - ap-south-1", value: "bom-1" },
      { label: "bom-2 - ap-south-1", value: "bom-2" },
      { label: "sin-1 - ap-southeast-1", value: "sin-1" },
      { label: "sin-2 - ap-southeast-1", value: "sin-2" },
      { label: "nrt-1 - ap-northeast-1", value: "nrt-1" },
      { label: "nrt-2 - ap-northeast-1", value: "nrt-2" },
      { label: "nrt-3 - ap-northeast-1", value: "nrt-3" },
      { label: "nrt-4 - ap-northeast-1", value: "nrt-4" },
      { label: "None", value: "None" },
    ],
  },
  maintenanceTicket: {
    type: "text",
    placeHolder: "Maintenance Ticket Number or N/A",
    options: undefined,
  },
  vendor: {
    type: "dropDown",
    placeHolder: "Select Vendor",
    options: [
      "Amagi",
      "Amazon Non-MCM",
      "Amazon MCM",
      "AMC",
      "AWS",
      "AZTECA",
      "CBC",
      "CBS",
      "Colt",
      "Corus",
      " Crown Castle",
      "Discovery",
      "EDM",
      "Elemental",
      "Eu Networks",
      "Fox",
      "Globo",
      "HBO",
      "IMDB",
      "IMG",
      "ITV",
      "Lumen (Century link)",
      "Media Choice",
      "M3Sat",
      "Nautical",
      "Other",
      "Overon",
      "PV Infra",
      "RTL",
      "SES",
      "Shaw",
      "Softbank",
      "SPI",
      "Starz",
      "Switch",
      "SyncBak",
      "TDF",
      "Telstra",
      "WURL",
      "Viacom",
      "Zayo",
      "6 Degrees",
    ],
  },
  startDate: {
    type: "date",
    placeHolder: "Select Start Date",
    options: undefined,
  },
  timeZone: {
    type: "dropDown",
    placeHolder: "Select Time Zone",
    options: [
      "US Pacific",
      "US Mountain",
      "US Central",
      "US Eastern",
      "EU Central (CEST)",
      "EU West",
      "Asia Pacific",
      "GMT",
    ],
  },
  endDate: {
    type: "date",
    placeHolder: "Select End Date",
    options: undefined,
  },
  status: {
    type: "dropDown",
    placeHolder: "Select Status",
    options: [
      "Cancelled",
      "Emergency",
      "Pending",
      "Scheduled",
      "Tentative",
      "Unplanned",
      "Courtesy",
    ],
  },
  overlapsWith: {
    type: "text",
    placeHolder: "Overlaps With or N/A",
    options: undefined,
  },
  opsPlan: {
    type: "textArea",
    placeHolder: "Ops Plan or N/A",
    options: undefined,
  },
  infrastructureComponent: {
    type: "textArea",
    placeHolder: "Infrastructure Component or N/A",
    options: undefined,
  },
  propertiesImpacted: {
    type: "textArea",
    placeHolder: "Feeds Impacted or N/A",
    options: undefined,
  },
  maintenanceImpact: {
    type: "textArea",
    placeHolder: "Maintenance Impact or N/A",
    options: undefined,
  },
  maintenanceReason: {
    type: "textArea",
    placeHolder: "Maintenance Reason or N/A",
    options: undefined,
  },
  maintenanceNotes: {
    type: "textArea",
    placeHolder: "Maintenance Notes or N/A",
    options: undefined,
  },
};

//Timezone drop-down Menu Options
const timeZoneOptions = {
  "US Pacific": "America/Los_Angeles",
  "US Mountain": "America/Denver",
  "US Central": "America/Chicago",
  "US Eastern": "America/New_York",
  "EU Central (CEST)": "Europe/Paris",
  "EU West": "Europe/London",
  "Asia Pacific": "Asia/Tokyo",
  GMT: "GMT",
};

const maintenanceFormat = [
  "id",
  "lastUpdatedBy",
  "status",
  "vendor",
  "maintenanceTicket",
  "infrastructureComponent",
  "timeZone",
  "startDate",
  "endDate",
  "affectedOrigins",
  "overlapsWith",
  "propertiesImpacted",
  "opsPlan",
  "maintenanceReason",
  "maintenanceImpact",
  "maintenanceNotes",
];

/* Local Constants */
const formErrorStyle = { color: "red", fontSize: "0.8vw", margin: "0px" };
const pickerDivStyle = { display: "flex", height: "40%", fontSize: "0.8rem" };

const maintenanceFormInitialValues = {
  id: "N/A",
  lastUpdatedBy: "N/A",
  infrastructureCategory: "",
  maintenanceTicket: "",
  vendor: "",
  startDate: "",
  timeZone: "",
  endDate: "",
  status: "",
  affectedOrigins: [],
  overlapsWith: "",
  opsPlan: "",
  infrastructureComponent: "",
  propertiesImpacted: "",
  maintenanceReason: "",
  maintenanceImpact: "",
  maintenanceNotes: "",
};

// Temp RBACK solution
// const accessGroups = {
//   admins: [
//     "ammandra",
//     "bewlli",
//     "brwilb",
//     "cwcook",
//     "huweih",
//     "jhhazen",
//     "lingbi",
//     "muraokad",
//     "swatpat",
//     "rratkie",
//     "kritsat",
//     "maxwelea",
//     "nelsomt",
//     "pfiore",
//     "sagcasac",
//     "myqjames",
//     "takukimu",
//     "lsouzaga",
//     "robifowl",
//     "robinxa",
//     "skchopra",
//     "ahmedmst",
//     "jmbarron",
//   ],
// };


export {
  pbFormConfig,
  formErrorStyle,
  pickerDivStyle,
  timeZoneOptions,
  maintenanceFormInitialValues,
  maintenanceFormat,
};
