import React, { useState, useContext, useEffect } from 'react';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from 'react-bootstrap-table2-paginator';
import styled from 'styled-components';
import { Button, Col, Row } from 'react-bootstrap';
import { faEdit, faTrashAlt, faCheck, faCaretDown, faCaretUp} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactTooltip from "react-tooltip";

//CSS imports
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

//Local imports
import { maintenanceFormat } from '../js/PbConstants';
import { PbContext } from '../contexts/PbContextProvider';

import { updateDBitem, deleteDBitem, getEmailBodyFromS3 } from '../js/PbAPIUtils';
// import { deleteLocalDBitem, updateLocalDBitem } from '../js/localAPIUtils';

const StyledPre = styled.pre`
  display: inline;
  margin: 0;
  font-size: 0.9vw;
  white-space:pre-line; 
  text-align: left;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
`

const PendingPinsViewer = (props) => {
    const [ selectedRow, setSelectedRow] = useState(null);
    const [ rowData, setRowData] = useState(null);

    const [ pbState, pbDispatch] = useContext(PbContext);
    let pendingPinsData = [];
    console.dir(pbState); //DEBUG AAM

    const show_columns = ["startDate", "id", "vendor", "maintenanceTicket", "infrastructureComponent"];
    const hide_columns = maintenanceFormat.filter( item => !show_columns.includes(item))
    hide_columns.push('createdDate'); //createdDate not added by default

    useEffect(() => {
        console.log("reloading component"); //DEBUG AAM
        console.log(pbState.pendingPinsData); //DEBUG AAM
        // console.log(Object.keys(pbState.pendingPinsData).length > 0); //DEBUG AAM
        if (Object.keys(pbState.pendingPinsData).length === 0) {
            setSelectedRow(null)
        }
        setRowData(null);
    }, [pbState.pendingPinsData]);

    // Logic: Get pending pins data 
    pendingPinsData = Object.keys(pbState.pendingPinsData).map( idx =>  {
        return pbState.pendingPinsData[idx];
        // return idx <1000 ? pbState.pendingPinsData[idx] : undefined
        })
    pendingPinsData =  pendingPinsData && Object.keys(pendingPinsData).length > 0 ? pendingPinsData.filter(function( element ) {
        return element !== undefined;
        }) : [];
    
    // console.log(pbState.pendingPinsData); //DEBUG AAM
    // console.log(Object.keys(pbState.pendingPinsData).length > 0); //DEBUG AAM
    // console.log(pendingPinsData); //DEBUG AAM


    const handleEditBtnClick = (rowData) => {
        console.log("You clicked handleEditBtnClick button");//DEBUG AAM

        //Logic to send pin to maintenance entry
        // console.log("before date conversion"); //DEBUG AAM
        // console.dir(rowData); //DEBUG AAM

        // fix for datepicker converting existing date to UTC because of ISO format.
        rowData['startDate'] =  rowData['startDate'].toLowerCase().includes("z") ? rowData['startDate'].split('.')[0] : rowData['startDate'];
        rowData['endDate']   =  rowData['endDate'].toLowerCase().includes("z") ? rowData['endDate'].split('.')[0] : rowData['endDate'];
        
        rowData['affectedOrigins']  = Array.isArray(rowData['affectedOrigins'])
        ? rowData['affectedOrigins'].map(x => { return({label: x, value: x})})
        : {label: "None", value: "None"}
        // console.log("after date conversion"); //DEBUG AAM
        console.dir(rowData); //DEBUG AAM

        pbDispatch({ type: 'SET_MFORM_VALUES', maintenanceFormValues: {...rowData} });
        pbDispatch({type: 'SHOW_MAINTENANCE_FORM'});
        pbDispatch({type: 'HIDE_VIEW_REPORTS'});
        pbDispatch({type: 'HIDE_VIEW_CALENDAR'});
        pbDispatch({type: 'HIDE_REPORT_DATA'});
        pbDispatch({type: 'FROM_REPORT_VIEWER', fromReportViewer: true});
        pbDispatch({ type: 'SHOW_PENDING_PINS', showPendingPins: false });
        // pbDispatch({ type: 'SHOW_S3_DATA_WINDOW', showS3DataWindow: false });
        pbDispatch({ type: 'SHOW_S3_DATA_DIV', showS3DataDiv: true });
        pbDispatch({ type: 'S3_WINDOW_OPEN_STATUS', s3DataWindowDivOpen: true });
        pbDispatch({ type: 'FROM_PENDING_PINS', fromPendingPins: true });
        pbDispatch({ type: 'SET_S3_OBJECT_KEY', s3ObjectKey: rowData.s3Objectkey });

        //Get S3 Details
        // getEmailBodyFromS3('maintenance/gkhmq1q9oips9a38l55k26c22vrbe30qrn6lso01').then( data => {
        //     console.log("Getting data via getEmailBodyFromS3")
        //     console.log(data); //DEBUG AAM
        // });
        

        // Delete from Pending Pins Variable / State
        deleteCurrentPendingPin();
    }
    
    const handleApproveBtnClick = async (rowData) => {
        console.log("You clicked handleApproveBtnClick button");
        console.log(`You want to approve ${selectedRow}` );
        
        // Logic to update DDB Entry
        try {
            rowData["pending"] = false;
            rowData["lastUpdatedBy"] = pbState.currentUser;
            const approveResponse = await updateDBitem(rowData);
            console.log(approveResponse);// DEBUG AAM
            if (approveResponse.data.ResponseMetadata.HTTPStatusCode === 200){
                //delete entry from UI
                deleteCurrentPendingPin();
            }  else throw new Error(`Error: Approve ticket with id : ${rowData['id']}` );
        } catch (updateErr) {
            console.log(updateErr);
          }
    }

    const handleRecordDeleteBtnClick = async (currentRowData) => {
        console.log("You clicked handleRecordDeleteBtnClick button");
        console.log(`You want to delete record at row: ${selectedRow}` );
        console.log(currentRowData);
        // Logic to delete from DDB
        try {
            const deleteResponse = await deleteDBitem(currentRowData['id']);
            console.log(deleteResponse);// DEBUG AAM
    
            if (deleteResponse.data.ResponseMetadata.HTTPStatusCode === 200){
                //delete entry from UI
                deleteCurrentPendingPin()
            }  else throw new Error(`Error: deleting ticket with id : ${currentRowData['id']}` );
        } catch (deleteErr) {
            console.log(deleteErr);
          }
    }

    const deleteCurrentPendingPin = () => {
        // Logic to delete from Pending Pins DB / Variable / State
        let idx = 0
        let newObj = {}

        //delete current row and resequence obj of obj
        console.log(Object.values(pbState.pendingPinsData)[selectedRow]); //DEBUG AAM
        delete pbState.pendingPinsData[selectedRow] && setSelectedRow(null);
       
        for (const [key,value] of Object.entries(pbState.pendingPinsData)){ 
            newObj[idx] = value;
            idx++;
        }
        console.log(newObj); //DEBUG AAM
        pbDispatch({ type: 'SET_PENDING_PINS_DATA', pendingPinsData: {...newObj} });
        setRowData(null);
        console.log(pbState.pendingPinsData); //DEBUG AAM
    }

    const processToolTipMsg = (msg) => {
        msg = !pbState.editRestrictions  && selectedRow !== null 
            ? 
                msg 
            : 
                <span className={'font-weight-bold'} > 
                    {msg} <br /> unavailable 
                </span>;
        return msg;
    }

     const expandRow = {
        renderer: row => (
            <React.Fragment>
                {console.log(row)}
                { 
                    hide_columns.map( (v, idx) => {
                       return v === 'maintenanceNotes' || v === 'propertiesImpacted' || v === 'maintenanceReason' || v === 'maintenanceImpact' 
                        ? 
                            <StyledPre key={`${v}-${idx}`} ><b> {v} </b>: {row[v]} <br /></StyledPre>
                        :
                            <div key={`${v}-${idx}`} ><b> {v} </b>: {row[v]} <br /></div>
                    })
                }
            </React.Fragment>
        ),  showExpandColumn: true
    };

    const selectRow = {
        mode: 'radio',
        clickToSelect: true,
        onSelect: (row, isSelect, rowIndex, e) => {
            console.log(row.id);
            console.log(isSelect);
            console.log(rowIndex);
            console.log(row);
            setSelectedRow(rowIndex);
            setRowData(row)
        }
    };

    const customSort = (order, column) => {
        if (!order) return (
            <>
                &nbsp; <FontAwesomeIcon  style={{ color: '#FF9900' }} icon={faCaretUp} /> <FontAwesomeIcon  style={{ color: '#FF9900' }} icon={faCaretDown}  />
            </>
        );
        else if (order === 'asc') return (<FontAwesomeIcon  style={{ color: '#FF9900' }} icon={faCaretUp}  />);
        else if (order === 'desc') return (<FontAwesomeIcon  style={{ color: '#FF9900' }} icon={faCaretDown}  />);
        
        return null;
    };

    
    const columns = show_columns.map( (v) =>
        { 
            return {
                dataField: v ,
                text: v.split(/(?=[A-Z])/).map( (s) => s.charAt(0).toUpperCase() + s.substring(1)).join(' '),
                sort: true,
                sortCaret: customSort,
                headerStyle: {'fontSize': '1vw'}
                // style: {'fontSize': '1vw'}
            }
        }
    );
    // console.log(columns); //DEBUG AAM

    return (
        <div className={'p-1 mt-1 bg-light'}>
            <React.Fragment>
                <Row >
                    <Col md={7} className={'ml-1 mr-1'} >
                        <h2 className="text-center ">Pending Pins</h2>
                    </Col>
                    <Col md={4} className={'ml-1 mr-1'}>
                        <Row>
                            <Col md={2} className={'text-center'} data-tip data-for='editButton'  >
                                <Button 
                                    variant="warning"
                                    size="md"
                                    disabled={!pbState.editRestrictions && selectedRow !== null ? false : true}
                                    onClick={(e) => handleEditBtnClick(rowData)} 
                                >
                                    <FontAwesomeIcon style={{ color: 'white' }} icon={faEdit}  /> 
                                </Button>
                                <ReactTooltip offset = {{'top': 10}} id="editButton" place="bottom" effect="solid">  {processToolTipMsg("Edit This Entry")} </ReactTooltip>
                            </Col>
                            <Col md={2} className={'text-center'} data-tip data-for='approveButton'   >
                                <Button 
                                    variant="warning"
                                    size="md"
                                    disabled={!pbState.editRestrictions && selectedRow !== null ? false : true}
                                    onClick={(e) => handleApproveBtnClick(rowData)} 
                                >
                                    <FontAwesomeIcon  style={{ color: 'white' }} icon={faCheck}  />
                                </Button>
                                <ReactTooltip  offset = {{'top': 10}} id="approveButton" place="bottom" effect="solid">  {processToolTipMsg("Approve This Entry")} </ReactTooltip>
                            </Col>
                            <Col md={2} className={'text-center'} data-tip data-for='deleteButton'   >  
                                <Button
                                    variant="warning"
                                    size="md"
                                    disabled={!pbState.editRestrictions && selectedRow !== null ? false : true}
                                    onClick={(e) => handleRecordDeleteBtnClick(rowData)} 
                                >
                                    <FontAwesomeIcon  style={{ color: 'white' }} icon={faTrashAlt}  />
                                </Button>
                                <ReactTooltip  offset = {{'top': 10}} id="deleteButton" place="bottom" effect="solid"> {processToolTipMsg("Delete This Entry")} </ReactTooltip>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </React.Fragment>
          <BootstrapTable
            striped
            hover
            pagination={ paginationFactory() }
            bordered={ false }
            keyField= 'id'
            data={ pendingPinsData} 
            columns={ columns }
            expandRow={ expandRow }
            selectRow={ selectRow }
            defaultSortDirection="asc"
          />
        </div>
      );
       
}

export default PendingPinsViewer;
