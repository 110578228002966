// ./js/PbCalLogic.js

// adjust this for your exact needs
const { Interval, DateTime} = require("luxon");

function* days(interval) {
    let cursor = interval.start.startOf("day");
    while (cursor < interval.end) {
    yield cursor;
    cursor = cursor.plus({ days: 1 });
    }
}

function getCalDates(monthOffset){
    let currentMonth = DateTime.local().plus({month: monthOffset});
    let start = currentMonth.startOf('month');
    let end = currentMonth.endOf('month');
    let prevMonthDays = start.weekday % 7;
    let nextMonthDays = 42 - end.day - prevMonthDays;
    console.log(`prevMonthDays = ${prevMonthDays} , currMonthDays= ${end.day}, nextMonthDays = ${nextMonthDays}`);

    //Add offset days to begin and end of the month
    start = start.minus({days: prevMonthDays});
    end = end.plus({days: nextMonthDays});
    let interval = Interval.fromDateTimes(start, end);

    // you can iterate over it
    let datesArray = [];
    for(var d of days(interval)) {
    // console.log(d.toISO());
    // console.log(d.day);
    datesArray.push(d.toISO());
    }

    // or construct an array out of it
    // var arr = Array.from(days(interval));
    // console.log(arr.length);
    return datesArray;
}

function getCalHeader(monthOffset){
    return DateTime.local().plus({month: monthOffset}).toFormat('MMMM yyyy');
}

function addDays (isoDate, days) {
  let myDate = new Date(isoDate);
  myDate.setDate(myDate.getDate() + parseInt(days));
  return myDate.toISOString();
}

export {getCalDates, getCalHeader, addDays};
    
