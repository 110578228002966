import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWrench, faTable, faEye, faExternalLinkAlt, faMapPin} from "@fortawesome/free-solid-svg-icons";
import { Card } from 'react-bootstrap';
import ReactTooltip from "react-tooltip";
import { PbContext } from '../contexts/PbContextProvider';
import {scanDBitemsDateRange, scanDBitemsMaintenance } from '../js/PbAPIUtils';
import { getCalDates, addDays } from "../js/PbCalLogic.js"
import {  getTzAdjustedDate,  updatedDBdataWithCalTimezone, getPendingPinsData } from '../js/PbMiscUtils';


const StyledCard = styled(Card) `
margin-top: 10%;
margin-bottom: 10%;
background-color: white;
padding: 5%;
text-align: center;
color: teal;
font-weight: 800;
border: 1px solid white;
border-radius: 8px;
width: 120%;
`

const StyledMenuBar = styled.div `
    display : flex;
    padding 0.5rem 0.5rem;
    display: table;
    height: 90%;
    width: 80%;
`

const StyledMenuButton = styled.div `
  background-color: white;
  border: 0.1rem solid white;
  padding 0.5rem;
  border-radius: 0.5rem;
  color: ${props => props.btnColor || "#e47911"};
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  font-size: ${props => props.fontSize ||  '1.0rem'};
  height: auto;
  pointer-events: ${props => props.pointerevents ||  'auto'};
  opacity: ${props => props.opacity ||  '1'};
  &:hover{
    border: 0.1rem solid #e47911;
    -webkit-transition: 0.4s;
  };
  &:active{
    border: 0.5rem solid #e47911;
    -webkit-transition: 0.4s;
  }
`

const handleViewCalClick = (pbState, pbDispatch) => {
  console.log("You clicked View Calendar button");
  let dateArray = getCalDates(pbState.currentMonth);
  scanDBitemsDateRange(addDays(dateArray[0], -2).split('T')[0], addDays(dateArray[dateArray.length - 1], 2).split('T')[0]).then( data => {
      pbDispatch({ type: 'SET_DB_DATA', dbData: {...updatedDBdataWithCalTimezone(data, pbState.timeZone)} });
      pbDispatch({ type: 'SET_SELECTED_VIEWPANE_DATA', selectedViewPaneData: {} });
      pbDispatch({ type: 'DB_DATA_RECEIVED', dbDataReceived: true });
      pbDispatch({ type: 'SHOW_DAY_CALENDAR' });
      pbDispatch({ type: 'SET_CLEAR_RECORDS', clearRecords: false });
      pbDispatch({type: 'SHOW_DB_DATA', showDBdata: true});
    });
  scanDBitemsMaintenance().then(data => {
      pbDispatch({ type: 'SET_PENDING_PINS_DATA', pendingPinsData: {...getPendingPinsData(data)} });
    });
  pbDispatch({type: 'SHOW_VIEW_CALENDAR'});
  pbDispatch({type: 'HIDE_REPORT_DATA'})
  pbDispatch({type: 'HIDE_MAINTENANCE_FORM'});
  pbDispatch({type: 'HIDE_VIEW_REPORTS'});
  pbDispatch({ type: 'SHOW_PENDING_PINS', showPendingPins: false });
  pbDispatch({ type: 'SHOW_S3_DATA_WINDOW', showS3DataWindow: false });
};

const handleLoadFormClick = (pbState, pbDispatch) => {
  console.log("You clicked Maintenance Form button");
  pbDispatch({type: 'HIDE_VIEW_CALENDAR'});
  pbDispatch({type: 'HIDE_REPORT_DATA'})
  pbDispatch({type: 'SHOW_MAINTENANCE_FORM'});
  pbDispatch({type: 'HIDE_VIEW_REPORTS'});
  pbDispatch({ type: 'SHOW_PENDING_PINS', showPendingPins: false });
  // pbDispatch({ type: 'SHOW_S3_DATA_WINDOW', showS3DataWindow: false });
};

const handleViewReportsClick = (pbState, pbDispatch) => {
  console.log("You clicked View Reports button");
  pbDispatch({type: 'HIDE_VIEW_CALENDAR'});
  pbDispatch({type: 'HIDE_MAINTENANCE_FORM'});
  pbDispatch({type: 'SHOW_VIEW_REPORTS'});
  pbDispatch({type: 'SHOW_REPORT_DATA'});
  pbDispatch({ type: 'SET_SEARCH_BY_MONTH', searchByMonth: true });
  pbDispatch({ type: 'SET_DB_DATA', dbData: {} });
  pbDispatch({ type: 'SET_SELECTED_VIEWPANE_DATA', selectedViewPaneData: {} });
  pbDispatch({ type: 'SET_CLEAR_RECORDS', clearRecords: true });
  pbDispatch({type: 'SHOW_DB_DATA', showDBdata: false});
  pbDispatch({ type: 'SHOW_PENDING_PINS', showPendingPins: false });
  // pbDispatch({ type: 'SHOW_S3_DATA_WINDOW', showS3DataWindow: false });
};

const handlePendingPinsClick = (pbState, pbDispatch) => {
  console.log("You clicked Pending Pins button");
  pbDispatch({type: 'HIDE_VIEW_CALENDAR'});
  pbDispatch({type: 'HIDE_MAINTENANCE_FORM'});
  pbDispatch({type: 'HIDE_REPORT_DATA'});
  pbDispatch({type: 'HIDE_VIEW_REPORTS'});
  pbDispatch({ type: 'SHOW_PENDING_PINS', showPendingPins: true });
  // pbDispatch({ type: 'SHOW_S3_DATA_WINDOW', showS3DataWindow: false });
}


export default function PbMenuBar(props) {
  const [ pbState, pbDispatch] = useContext(PbContext);
  console.dir(pbState); //DEBUG AAM

  useEffect( () => {
    console.log("Checking user and restrictions");
    console.log( localStorage.getItem('access') );
    console.log( localStorage.getItem('access') === "admin" ? false : true);
  },[]);
  
  return (  
    <StyledMenuBar>
      <StyledCard>
        <StyledMenuButton onClick={() => handleViewCalClick(pbState, pbDispatch)}>
          <FontAwesomeIcon icon={faEye} /> &nbsp; View Calendar 
          </StyledMenuButton>
      </StyledCard>

      <StyledCard data-tip data-for='newEntry'>
        <StyledMenuButton 
          pointerevents={pbState.editRestrictions ? "none" : "auto"} 
          opacity={pbState.editRestrictions ? "0.4" : "1"} 
          onClick={() => handleLoadFormClick(pbState, pbDispatch)} 
        >
          <FontAwesomeIcon icon={faWrench} /> &nbsp; New Maintenance Entry  
          {
            pbState.editRestrictions ? <ReactTooltip 
              id='newEntry'
              place="bottom"
              offset = {{'left': 150}}
              effect="solid"
            > 
              Access restriced
            </ReactTooltip>
            : ""
          }
        </StyledMenuButton> 
      </StyledCard>

      <StyledCard>
        <StyledMenuButton 
          onClick={() => handleViewReportsClick(pbState, pbDispatch)}>
          <FontAwesomeIcon  icon={faTable} />&nbsp;  View Reports 
        </StyledMenuButton>
      </StyledCard>

      <StyledCard data-tip data-for='pendingPins'>
        <StyledMenuButton 
          pointerevents={pbState.editRestrictions ? "none" : "auto"} 
          opacity={pbState.editRestrictions ? "0.4" : "1"} 
          onClick={() => handlePendingPinsClick(pbState, pbDispatch)}>
          <FontAwesomeIcon  icon={faMapPin} />&nbsp; Pending Pins
          {
            pbState.editRestrictions ? <ReactTooltip 
              id='pendingPins'
              place="bottom"
              offset = {{'left': 150}}
              effect="solid"
            > 
              Access restriced
            </ReactTooltip>
            : ""
          }
        </StyledMenuButton>
      </StyledCard>
    </StyledMenuBar>
  )
}
