import React, { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { Card, Container,Col, Row, Button } from 'react-bootstrap';
import { faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PbContext } from '../contexts/PbContextProvider';
import ReactTooltip from "react-tooltip";
import { getEmailBodyFromS3 } from '../js/PbAPIUtils';
import { PbSpinner } from './PbSpinner';

const StyledContainer = styled(Container)`
  background-color: white;
  padding: 1%;
  font-size: 0.8vw;
  margin-left: 0.2rem;
  border: 1px solid white;
  border-radius: 0.5rem;
  width: 100%;
`

const StyledFormHeader = styled.h5 `
  justify-content: center;
  align-items: center;
  margin: 0 auto; 
  text-align: center;
  font-size: 1.6vw;
  font-weight: bold;
  letter-spacing: 0.2rem;
  //  margin-bottom: 12px;
  color: #e47911; /* Amazon Orange */
`;

const StyledBottomBorder = styled.div `
  margin-bottom: 1.5rem;
  width:100%; 
  border-bottom: 0.1rem solid #D3D3D3;
`;

const EmailCol = styled(Col)`
  background-color: white;
	// position: absolute;
	overflow-y: scroll; 
	background-color: white;
  	padding: 1%;
  	font-size: 0.6vw;
  	margin-left: 0.2rem;
  	width: 100%;
    height:90vh;
`


export const S3DataLoader = () => {
	const [s3Data, setS3Data] = useState(null);
    const [ pbState, pbDispatch] = useContext(PbContext);
	console.log("I am inside S3DataLoader"); //DEBUG AAM

    const handleS3DataWindowClose = () => {
      console.log( " closing Original Email Body ");
      pbDispatch({ type: 'SHOW_S3_DATA_DIV', showS3DataDiv: false });
      pbDispatch({ type: 'S3_WINDOW_OPEN_STATUS', s3DataWindowDivOpen: false });
      console.log(pbState);
    }

	useEffect( () => {
		console.log("Getting data via getEmailBodyFromS3")

		// const data = getEmailBodyFromS3(pbState.s3ObjectKey)
		// if (data === null){
		// 	setS3Data(`<h4> No S3 data found </h4>`);
		// } else {
		// 	setS3Data(data);
		// }

		getEmailBodyFromS3(pbState.s3ObjectKey).then( data => {
			console.log(data); //DEBUG AAM
			if (data === null){
				setS3Data(`<h4> No S3 data found </h4>`);
			} else {
				setS3Data(data);
			}
		});
	},[]);

    return (
      <StyledContainer>
        <Row>
          <Col>
            <StyledFormHeader>Original Email Body</StyledFormHeader>
          </Col>
          <Col md="3">
            <Button variant="outline-danger" size="sm" className="mb-2" data-tip data-for="closeS3DivButton" onClick={() => handleS3DataWindowClose()}>
              Close <FontAwesomeIcon icon={faWindowClose} />
              <ReactTooltip offset = {{'top': 4}} id="closeS3DivButton" place="bottom" effect="solid">  {"Close This Div"} </ReactTooltip>
            </Button>
          </Col>
        </Row>
        <Row><StyledBottomBorder> </StyledBottomBorder></Row>
        <Row>
			{
				!s3Data 
				? 
					<Col>
						< PbSpinner />
					</Col>
				:
					<EmailCol>
						< HTML s3Data = {s3Data} />
					</EmailCol>
			}
        </Row>
      </StyledContainer>
    )
  }

  const HTML = (props) => {
    return (
	    <div dangerouslySetInnerHTML={{ __html: props.s3Data }}/>
    )
  }

  