import React from "react";
import {Auth} from "aws-amplify";
import styled from 'styled-components';
import { Container, Button, Card } from 'react-bootstrap';
import logo from '../pinboardLogo.svg';

// const LoginContainer = styled(Container) `
// text-align: center;  
// width: 40vw;  
// height: 40vw;  
// padding-top: 15vw; 
// `;

const StyledLogo = styled.span`
  padding: 1%;
  text-align: left;
  font-size: 1.4vw;
  color: #84DE02;
  text-shadow: 0.5px 0.5px;
  font-weight: bolder;
  width: 110%;
  pointer-events: none;
`

const LoginContainer = styled.div `
    display: flex;
    flex-direction: column;
    height: calc(100vh - 5rem);
    align-items: center;
    justify-content: center;
    font-size: 2rem;

    div{
    width: auto;
    }
`

export const Login = () => {
    //const { dispatch } = React.useContext(AuthContext);
    const signIn = async (e) => {
        e.preventDefault();
        try {
            console.log("Logging in via AWS-Federate-via-Midway");
            await Auth.federatedSignIn({provider: "AWS-Federate-via-Midway"});
        } catch (error) {
            console.log('error signing in', error);
        }
    }
  return (
    <Card
        className="text-center"
        style={{ border: 'none', width: '24rem', height: '40vh', marginLeft: 'auto', marginRight: 'auto', marginTop: '30vh' }}
    >
        <Card.Header as="h5" style={{ background: 'gray', pointerEvents: 'none'}} >
            <a className="navbar-brand" href="#">
                <div className="logo-image">
                    <img src={logo} className="logo-img" alt="PinbordLogo" />
                    <StyledLogo> PinBoard</StyledLogo>
                </div>
            </a>
        </Card.Header>
        <Card.Body>
            <Card.Title as="h3" ><br /> Please Login <br /> <br /> </Card.Title>
                <Button
                    variant="primary"
                    onClick={signIn}
                    style={{ background: '#FF9900', border: 'none'}}
                    size="lg"
                > 
                    Sign In
                </Button>
        </Card.Body>
    </Card>
  );
};

export default Login;
