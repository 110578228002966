import React from 'react';
export const PbSpinner = (props) => {

    return (
        <div class="text-center" style={{marginTop: '20vh',marginBottom: '20vh'}}>
            <div class="spinner-grow text-dark" style={{width: '6rem', height: '6rem'}} role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>
    )
}

