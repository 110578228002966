import React, { useContext, useEffect } from 'react';
import { Card, Container, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';

import PbMenuBar  from './PbMenuBar';
import PbCalViewer from './PbCalViewer';
import MaintenanceForm from './PbMaintenanceForm';
import ViewReports from './PbViewReports';
import PendingPinsViewer from './PendingPinsViewer';
import { PbContext } from '../contexts/PbContextProvider';

const PbContainer = styled(Container) `
  display: block;
  padding: 0.1rem;
  background: #f1f1f1;
  height: 100vh;
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
`;

const MenuCard = styled(Card)`
  padding: 0.1rem;
  background: #f1f1f1;
  height: 100vh;
  max-width: 100%;
  border: 0.1rem solid #f1f1f1;
`

const Pinboard = () => {
  const [ pbState, pbDispatch] = useContext(PbContext);

  useEffect( () => {
    pbDispatch({ type: 'HIDE_MAINTENANCE_FORM'});
    pbDispatch({ type: 'LASTUPDATEDBY', lastUpdatedBy: localStorage.getItem('user') });
    pbDispatch({ type: 'SET_CURRENT_USER', currentUser: localStorage.getItem('user') });
    console.log(`localStorage.getItem('user') = ${localStorage.getItem('user')}`) //DEBUG AAM
    console.log(`localStorage.getItem('access') = ${localStorage.getItem('access')}`)
    pbDispatch({ type: 'SET_EDIT_RESTRICTIONS', editRestrictions: localStorage.getItem('access') === "admin" ? false : true });
  },[pbState.currentUser]);

  return (
      <PbContainer fluid>
        <Row noGutters>
          <Col  sm='2' md='2' lg='2'>
            <MenuCard> 
              <PbMenuBar /> 
            </MenuCard>
          </Col>
          <Col sm='10' md='10' lg='10'>
            <Row >
              <Col >
                {/*  right top pane  */}
                  { 
                    pbState.showViewCal ?  <PbCalViewer /> : null
                  }
                  {
                    pbState.showMaintenanceForm ? <MaintenanceForm /> : null
                  }

                  {
                    pbState.showViewReports ? <ViewReports><h4> View Reports Here</h4></ViewReports> : null
                  }

                  {
                    pbState.showPendingPins ? <PendingPinsViewer><h4> View Pending Pins Here</h4></PendingPinsViewer> : ''
                  }
              </Col>
            </Row>
          </Col>
        </Row>
      </PbContainer>
  );
};

export default Pinboard;
