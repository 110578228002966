import { Auth } from "aws-amplify";
import jwt from 'jwt-decode';
import React, { useContext, useEffect, useRef } from 'react';
import { AuthContext } from '../contexts/AuthProvider';

const tokenPollPeriod = 30000; //10 seconds

// sleep for k seconds
const sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
}

const useInterval = (callback, delay) => {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick() {
            savedCallback.current();
        }
        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}



// Validate token in a continous loop 
export const TokenValidator = () => {
    const [AuthDispatch] = useContext(AuthContext);

    const handleTokenExpiry = () => {
        console.warn("Token expired, Please refresh page (Auto-redirect in couple of seconds)");
        sleep(2000); // 2 sec
        localStorage.clear();
        let token = null;
        let user = null;
        AuthDispatch({
            type: 'LOGOUT',
            payload: {
                user,
                token
            }
        })
        Auth.signOut();

    }

    // Helper function to check token validation
    const CheckToken = () => {
        const user = localStorage.getItem('user') || null;
        const token = localStorage.getItem('token') || null;
        if (user && token) {

            //Check token validity. Relogin if necessary
            let userTokenExpEpochDate = jwt(token).exp;
            let currentEpochDate = Math.round(Date.now() / 1000);
            if (currentEpochDate >= userTokenExpEpochDate) {
                //Token Expired
                handleTokenExpiry()
            }
        } else {
            // No user & Token found - force relogin
            handleTokenExpiry()

        }
    }

    useInterval(() => {
        CheckToken();
    }, tokenPollPeriod);

    return (
        <React.Fragment>
            <>  </>
        </React.Fragment>
    )
}