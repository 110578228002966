import React, { useContext } from 'react';
import styled from 'styled-components';
import { Dropdown, Button, ButtonGroup, Row, Col  } from 'react-bootstrap';
import { faAngleLeft, faAngleRight, faDotCircle } from "@fortawesome/free-solid-svg-icons";
import { faCalendarWeek } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactTooltip from "react-tooltip";
import { pbFormConfig } from '../js/PbConstants';
import { PbContext } from '../contexts/PbContextProvider';
import {  getTzAdjustedDate,  updatedDBdataWithCalTimezone, getPendingPinsData } from '../js/PbMiscUtils';
import {scanDBitemsDateRange, scanDBitemsMaintenance} from '../js/PbAPIUtils';
import { DateTime } from "luxon";
import { getCalDates, addDays } from "../js/PbCalLogic.js"

const StyledButton = styled(Button)`
  background-color: #e47911; /* Amazon Orange */
  &:hover{
    border: 0.1rem solid #e47911;
    background-color: #e47911; /* Amazon Orange */
  };
  &:not(:hover){
    border: 0.1rem solid #e47911;
    background-color: #e47911; /* Amazon Orange */
  }
`

const StyledPbCalHeader = styled.div `
    padding: 2px;
    overflow: hidden;
    vertical-align: center;
    justify-content: center;
    align-items: center;
    margin: 0 auto; 
    text-align: right;
    font-size: 1.6vw;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 0.2rem;
    color: #e47911; /* Amazon Orange */
`



export default function PbCalNavBar(props) {
  const [ pbState, pbDispatch] = useContext(PbContext);


const handlePrevBtnClick = (pbState, pbDispatch) => {
  console.log("You clicked Prev button"); //DEBUG AAM
  pbDispatch({ type: 'SET_CURRENT_MONTH', currentMonth: pbState.currentMonth - 1 });
  let dateArray = getCalDates(pbState.currentMonth - 1);
  scanDBitemsDateRange(addDays(dateArray[0], -2).split('T')[0], addDays(dateArray[dateArray.length - 1], 2).split('T')[0]).then( data => {
      pbDispatch({ type: 'SET_DB_DATA', dbData: {...updatedDBdataWithCalTimezone(data, pbState.timeZone)} });
      pbDispatch({ type: 'SET_SELECTED_VIEWPANE_DATA', selectedViewPaneData: {} });
      pbDispatch({ type: 'DB_DATA_RECEIVED', dbDataReceived: true });
      pbDispatch({ type: 'SHOW_DAY_CALENDAR' });
      pbDispatch({ type: 'SET_CLEAR_RECORDS', clearRecords: false });
      pbDispatch({type: 'SHOW_DB_DATA', showDBdata: true});
    });
  scanDBitemsMaintenance().then(data => {
      pbDispatch({ type: 'SET_PENDING_PINS_DATA', pendingPinsData: {...getPendingPinsData(data)} });
    })
};

const handleNextBtnClick = (pbState, pbDispatch) => {
  console.log("You clicked Next button"); //DEBUG AAM
  pbDispatch({ type: 'SET_CURRENT_MONTH', currentMonth: pbState.currentMonth + 1 });
  let dateArray = getCalDates(pbState.currentMonth + 1);
  scanDBitemsDateRange(addDays(dateArray[0], -2).split('T')[0], addDays(dateArray[dateArray.length - 1], 2).split('T')[0]).then( data => {
      pbDispatch({ type: 'SET_DB_DATA', dbData: {...updatedDBdataWithCalTimezone(data, pbState.timeZone)} });
      pbDispatch({ type: 'SET_SELECTED_VIEWPANE_DATA', selectedViewPaneData: {} });
      pbDispatch({ type: 'DB_DATA_RECEIVED', dbDataReceived: true });
      pbDispatch({ type: 'SHOW_DAY_CALENDAR' });
      pbDispatch({ type: 'SET_CLEAR_RECORDS', clearRecords: false });
      pbDispatch({type: 'SHOW_DB_DATA', showDBdata: true});
    });
  scanDBitemsMaintenance().then(data => {
      pbDispatch({ type: 'SET_PENDING_PINS_DATA', pendingPinsData: {...getPendingPinsData(data)} });
    })
};

const handleTodayBtnClick = (pbState, pbDispatch) => {
  console.log("You clicked Today button"); //DEBUG AAM
  pbDispatch({ type: 'SET_CURRENT_MONTH', currentMonth: 0 });
  let dateArray = getCalDates(0);
  scanDBitemsDateRange(addDays(dateArray[0], -2).split('T')[0], addDays(dateArray[dateArray.length - 1], 2).split('T')[0]).then( data => {
      pbDispatch({ type: 'SET_DB_DATA', dbData: {...updatedDBdataWithCalTimezone(data, pbState.timeZone)} });
      pbDispatch({ type: 'SET_SELECTED_VIEWPANE_DATA', selectedViewPaneData: {} });
      pbDispatch({ type: 'DB_DATA_RECEIVED', dbDataReceived: true });
      pbDispatch({ type: 'SHOW_DAY_CALENDAR' });
      pbDispatch({ type: 'SET_CLEAR_RECORDS', clearRecords: false });
      pbDispatch({type: 'SHOW_DB_DATA', showDBdata: true});
    });
  scanDBitemsMaintenance().then(data => {
      pbDispatch({ type: 'SET_PENDING_PINS_DATA', pendingPinsData: {...getPendingPinsData(data)} });
    })
};

const handleTzBtnClick = (tz, pbState, pbDispatch) => {
  console.log("You clicked TimeZone button"); //DEBUG AAM
  pbDispatch({ type: 'SET_TIMEZONE', timeZone: tz });
  pbDispatch({ type: 'SET_DB_DATA', dbData: {...updatedDBdataWithCalTimezone(pbState.dbData, tz)} });
  console.dir(tz);
};

  return (
      <Row>
        <Col md={4} className=''>
          <StyledButton size="sm" className="mr-1" onClick={() => handlePrevBtnClick( pbState, pbDispatch)} data-tip data-for='prevMonthButton'>
            Prev <FontAwesomeIcon icon={faAngleLeft}/>
            <ReactTooltip id='prevMonthButton' place="bottom" effect="solid"> Go to Previous Month </ReactTooltip>
          </StyledButton>
          
          <StyledButton size="sm" className="mr-1" onClick={() => handleNextBtnClick( pbState, pbDispatch)} data-tip data-for='nextMonthButton'>
            Next <FontAwesomeIcon icon={faAngleRight} />
            <ReactTooltip id='nextMonthButton' place="bottom" effect="solid"> Go to Next Month </ReactTooltip>
          </StyledButton>
        
          <StyledButton  size="sm" className="mr-1" onClick={() => handleTodayBtnClick( pbState, pbDispatch)} data-tip data-for='todayButton'>
            Today <FontAwesomeIcon icon={faDotCircle} />
            <ReactTooltip id='todayButton' place="bottom" effect="solid"> Jump to Today </ReactTooltip>
          </StyledButton>
          
         
        </Col>
        <Col md={4} className=''>
          <StyledPbCalHeader>{props.calHeader}</StyledPbCalHeader>
        </Col>
        <Col md={2} className=''>
          <Dropdown 
            as={ButtonGroup}
            title="timeZones"
            id="menubar-timezone-dropdown"
            onSelect={(tz ) => handleTzBtnClick( tz, pbState, pbDispatch)}
          > 
            <Button size="sm" variant="secondary"> {pbState.timeZone} </Button>
            <Dropdown.Toggle split variant="warning" id="dropdown-split-basic" data-tip data-for='tzMenuDropdown'/>
            <ReactTooltip id='tzMenuDropdown' place="bottom" effect="solid"> Timezone Selection Dropdown </ReactTooltip>
            <Dropdown.Menu>
              {   
                  pbFormConfig.timeZone.options.map((item, idx) => (
                    <Dropdown.Item key={idx} eventKey={item} >{item}</Dropdown.Item>
                  ))
              }
            </Dropdown.Menu>
          </Dropdown>
        </Col>
        <Col md={2} className='' data-tip data-for='weeklyCalendar'>
          <Button size="sm" type="button" className="btn btn-secondary  mr-3" disabled>
              Week <FontAwesomeIcon icon={faCalendarWeek}  />
          </Button>
          <ReactTooltip id='weeklyCalendar' place="bottom" effect="solid"> Weekly Calendar (Disabled) </ReactTooltip>
        </Col>
      </Row>
  )
}