
import { DateTime } from "luxon";
import { timeZoneOptions } from '../js/PbConstants';

function getTzAdjustedDate( currentDate, currentTimeZone, selectedTimeZone){
    /* Convert DB date & timezone to Dropdown time zone and display */
    let startDateWithOffSet = DateTime.fromISO(currentDate, { zone: timeZoneOptions[currentTimeZone]}).setZone(timeZoneOptions[selectedTimeZone]).toISO();
    return startDateWithOffSet;
  }

function updatedDBdataWithCalTimezone(data, timeZone){
  /* Convert DB date & timezone to Dropdown time zone and update DB data */
  let calData = [];
  Object.keys(data).forEach( idx => {
    // Pending pins field  doesnt exists - for older records 
    if (data[idx]['pending'] === undefined){
      data[idx]['startDate']  = getTzAdjustedDate( data[idx]['startDate'], data[idx]['timeZone'], timeZone);
      data[idx]['endDate'] = getTzAdjustedDate( data[idx]['endDate'], data[idx]['timeZone'], timeZone);
      data[idx]['timeZone'] = timeZone;
      calData.push(data[idx]);
    } else {
      // newer records that are cleared from pending pins
      if (data[idx]['pending'] === false ){
        data[idx]['startDate']  = getTzAdjustedDate( data[idx]['startDate'], data[idx]['timeZone'], timeZone);
        data[idx]['endDate'] = getTzAdjustedDate( data[idx]['endDate'], data[idx]['timeZone'], timeZone);
        data[idx]['timeZone'] = timeZone;
        calData.push(data[idx]);
      } 
    }
  })
  // return data;
  return calData;
}

  // function updatedDBdataWithCalTimezone(data, timeZone){
  //   /* Convert DB date & timezone to Dropdown time zone and update DB data */
  //   Object.keys(data).forEach( idx => {
  //    data[idx]['startDate']  = getTzAdjustedDate( data[idx]['startDate'], data[idx]['timeZone'], timeZone);
  //    data[idx]['endDate'] = getTzAdjustedDate( data[idx]['endDate'], data[idx]['timeZone'], timeZone);
  //    data[idx]['timeZone'] = timeZone;
  //   })
  //   // console.log("updatedDBdataWithCalTimezone=>" + JSON.stringify(data, null, 2)); //DEBUG AAM
  //   return data;
  // }

function getPendingPinsData(data){
  /* Handle pending pins data */
  let pendingPinsData = []
  Object.keys(data).forEach( idx => {
    // Pending pins field  doesnt exists - for older records 
    if (data[idx]['pending'] !== undefined && data[idx]['pending'] === true ){
      data[idx]['lastUpdatedBy'] = data[idx]['createdBy']
      pendingPinsData.push(data[idx])
    } 
  })
  return pendingPinsData;
}
  
export {
  getTzAdjustedDate,
  updatedDBdataWithCalTimezone,
  getPendingPinsData,
}
