import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { Auth, Hub } from "aws-amplify";
import jwt from 'jwt-decode';
import { DateTime } from 'luxon';
import React, { useContext } from 'react';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';

import Login from "./components/Login";
import NavBar from './components/MainNavBar';
import Pinboard from "./components/Pinboard";
import { TokenValidator } from './components/TokenValidator';
import { AuthContext } from './contexts/AuthProvider';
import { PbContextProvider } from './contexts/PbContextProvider';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

// import {
//   BrowserRouter as Router,
//   Switch,
//   Route
// } from "react-router-dom";


library.add(fas);

/*  ---------------- private variables ---------------------  */
const AppContainer = styled(Container)`
  // background: #f1f1f1;
  // height: 60vw;
  max-width: 100%;
`;

/*  ---------------- public functions ---------------------  */

function App() {
  const [AuthState, AuthDispatch] = useContext(AuthContext);

  React.useEffect(() => {
    const user = localStorage.getItem('user') || null;
    const token = localStorage.getItem('token') || null;
    const access = localStorage.getItem('access') || null;
    console.log("user=>" + user); //DEBUG AAM
    console.log("token=>" + token); //DEBUG AAM
    console.log("access=>" + access)
    if (user && token) {

      //Check token validity. Relogin if necessary
      let userTokenExpEpochDate = jwt(token).exp;
      // console.log(`currentEpochDate = ${DateTime.local().toSeconds().toString().split('.')[0]}`); //DEBUG AAM
      let currentEpochDate = DateTime.local().toSeconds().toString().split('.')[0]
      console.log(`currentEpochDate = ${currentEpochDate} ; userTokenExpEpochDate = ${userTokenExpEpochDate}`)
      if (currentEpochDate >= userTokenExpEpochDate) {
        //Token Expired
        console.log("Token expired, Signout in progress");
        Auth.signOut();
        localStorage.clear();
        let token = null;
        let user = null;
        AuthDispatch({
          type: 'LOGOUT',
          payload: {
            user,
            token
          }
        })
      } else {
        //token valid
        console.log("Token valid, Sigin in progress");
        AuthDispatch({
          type: 'LOGIN',
          payload: {
            user,
            token,
            access
          }
        })
      }
      // AuthDispatch({
      //   type: 'LOGIN',
      //   payload: {
      //     user,
      //     token
      //   }
      // })
    }
  }, [])

  React.useEffect(() => {
    Hub.listen("auth", ({ payload: { event, data } }) => {
      console.dir(process.env);

      switch (event) {
        case "signIn":
          console.log("signIn in progress");
          console.dir(data);
          break;

        case "cognitoHostedUI":
          console.log("In cognitoHostedUI");
          console.dir(data);

          Auth.currentSession().then(session => {
            console.dir(session);
            const user = session.idToken.payload.identities[0].userId || null;
            const token = session.accessToken.jwtToken;
            const access = session.idToken.payload["custom:posix_groups"];
            console.log("session user=>" + user); //DEBUG AAM
            console.log("session token=>" + token); //DEBUG AAM
            console.log("session access=>" + access);

            //Check token validity. Relogin if necessary
            let userTokenExpEpochDate = jwt(token).exp;
            let currentEpochDate = DateTime.local().toSeconds().toString().split('.')[0]
            console.log(`currentEpochDate = ${currentEpochDate} ; userTokenExpEpochDate = ${userTokenExpEpochDate}`)
            if (currentEpochDate >= userTokenExpEpochDate) {
              //Token Expired
              console.log("Token expired, Signout in progress");
              Auth.signOut();
              localStorage.clear();
              let token = null;
              let user = null;
              AuthDispatch({
                type: 'LOGOUT',
                payload: {
                  user,
                  token,
                  access
                }
              });
            } else {
              //token valid
              console.log("Token valid, Sigin in progress");
              AuthDispatch({
                type: 'LOGIN',
                payload: {
                  user,
                  token,
                  access
                }
              });
            }
          });
          break;

        case "signOut":
          console.log("signOut in progress");
          console.dir(data);
          localStorage.clear();
          let token = null;
          let user = null;
          AuthDispatch({
            type: 'LOGOUT',
            payload: {
              user,
              token
            }
          })
          break;

        case "oAuthSignOut":
          console.log("oAuthSignOut in progress");
          console.dir(data);
          localStorage.clear();
          token = null;
          user = null;
          AuthDispatch({
            type: 'LOGOUT',
            payload: {
              user,
              token
            }
          })
          break;

        case "signIn_failure":
          console.log("signIn_failure ");
          console.dir(data);
          localStorage.clear();
          token = null;
          user = null;
          AuthDispatch({
            type: 'LOGOUT',
            payload: {
              user,
              token
            }
          })
          break;

        case "cognitoHostedUI_failure":
          console.log("cognitoHostedUI_failure", data);
          console.dir(data);
          localStorage.clear();
          token = null;
          user = null;
          AuthDispatch({
            type: 'LOGOUT',
            payload: {
              user,
              token
            }
          })
          break;

        default:
          console.dir(data);
          console.dir(event);
          console.log("In Hub Default option.");
      }
    });
  }, []);

  return (
    // For local load, convert  !AuthState.isAuthenticated  to AuthState.isAuthenticated 
    <AppContainer fluid>
      {AuthState.isAuthenticated === false ? "" : <NavBar user={AuthState.user} />}
      <PbContextProvider>
        {console.dir(AuthState)}
        {console.log("AuthState.isAuthenticated " + AuthState.isAuthenticated)}
        <TokenValidator />
        {AuthState.isAuthenticated === false ? <Login /> : <Pinboard />}
      </PbContextProvider>
    </AppContainer>
  )
}

export default App;
