import React, { useContext } from 'react';
import styled from 'styled-components'
import {Auth} from "aws-amplify";
import { Button, Navbar, Nav, NavDropdown} from 'react-bootstrap';
import ReactTooltip from "react-tooltip";
import { faExternalLinkAlt} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { AuthContext } from '../contexts/AuthProvider';
import logo from '../pinboardLogo.svg';

const StyledLogo = styled.span`
  padding: 1%;
  text-align: left;
  font-size: 1.4vw;
  color: #84DE02;
  text-shadow: 0.5px 0.5px;
  font-weight: bolder;
  width: 110%;
`

const StyledMenuLink = styled(Nav.Link)`
  font-weight: 800;
  font-size: 1.6vw;
`


const StyledNavDropdown = styled(NavDropdown)`
  font-weight: 800;
  font-size: 1.6vw;
`


const StyledNavBar = styled(Navbar)`
  height: 6em;
  width: 100% !important;
  margin: 0;
`

export default function NavBar(props) {
  const [ AuthState, AuthDispatch] = useContext(AuthContext);

  async function signOut() {
    try {
      await Auth.signOut();
      localStorage.clear();
      console.log("signOut in progress");
      console.dir(AuthState); //DEBUG AAM
      let token = null;
      let user = null;
      AuthDispatch({
        type: 'LOGOUT',
        payload: {
          user,
          token
        }
      });
      console.dir(AuthState); //DEBUG AAM
    } catch (error) {
      console.log('error signing out', error);
    }
  }

  let userName = props.user !== null ? `${props.user}` : undefined;

  return (
    <StyledNavBar collapseOnSelect expand="lg" bg="dark" variant="dark" sticky="top">
      <Navbar.Brand>
        <a class="navbar-brand" href="#">
              <div class="logo-image">
                    <img src={logo} className="logo-img" alt="PinbordLogo" />
                    <StyledLogo> PinBoard</StyledLogo>
              </div>
        </a>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto">
          {/* <Nav.Item>
            <StyledMenuLink href="#home">Home</StyledMenuLink>
          </Nav.Item> */}
          <StyledNavDropdown title="Links" id="nav-dropdown" className='font-size-0-8'>
            <NavDropdown.Item onClick={()=> window.open("https://quip-amazon.com/RgWfAb0NPaaY/Pending-Pins#fSf9CATqu8D", "_blank")}>
              Pending Pins  <FontAwesomeIcon className='ml-2 text-secondary'  icon={faExternalLinkAlt}/>
            </NavDropdown.Item>
            <NavDropdown.Item onClick={()=> window.open("https://quip-amazon.com/M3RsA5yaKWee/Untitled#KSQ9CAEiEJ9")}>
              Submit a Bug  <FontAwesomeIcon className='ml-2 text-secondary'  icon={faExternalLinkAlt}/>
            </NavDropdown.Item>
            <NavDropdown.Item onClick={()=> window.open("https://quip-amazon.com/kCAtAv0GBLqV/DXs#XMR9CA9EeeN")}>
              DX's  <FontAwesomeIcon className='ml-2 text-secondary'  icon={faExternalLinkAlt}/>
            </NavDropdown.Item>
            <NavDropdown.Item onClick={()=> window.open("https://w.amazon.com/bin/view/AIV/LiveEvents/LiveOps/Infrastructure/reference/circuits")}>
              Fiber Circuit Id's  <FontAwesomeIcon className='ml-2 text-secondary'  icon={faExternalLinkAlt}/>
            </NavDropdown.Item>
            <NavDropdown.Item onClick={()=> window.open("https://quip-amazon.com/SGYJA9eriwIm/THE-Linear-Ops-Support-DOC-Cerebro-data-collection#bZE9CAMdqST")}>
              Linear Ops Support Doc  <FontAwesomeIcon className='ml-2 text-secondary'  icon={faExternalLinkAlt}/>
            </NavDropdown.Item>
          </StyledNavDropdown>
        </Nav>
        <Navbar.Brand href="#branch" className='font-size-0-8'>
          { process.env.REACT_APP_API_ENVIRONMENT === "Prod" ? null: ` Branch: ${process.env.REACT_APP_API_ENVIRONMENT}`}
        </Navbar.Brand>
        <Navbar.Brand href="#user" className='font-size-0-8'>
          <img alt=''
            src={`https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${userName}`}
            style={{verticalAlign: 'middle', borderRadius: '10%', height: '2.0rem'}}
          /> {userName}
        </Navbar.Brand>
        <Nav>
          <Button variant="outline-danger" size="sm" className="mr-5" data-tip data-for='exitButton' onClick={signOut}>
            Logout
          </Button>
          <ReactTooltip id="exitButton" place="bottom" effect="solid">
              Exit PinBoard 
          </ReactTooltip>
        </Nav>
      </Navbar.Collapse>
    </StyledNavBar>
  )
}

