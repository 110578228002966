import React from 'react';
import ReactDOM from 'react-dom';
import Amplify from 'aws-amplify';

import {AuthContextProvider} from './contexts/AuthProvider';
import selectedConfig from './config/finalConfig';
import App from './App';

import './index.css';
import "react-datepicker/dist/react-datepicker.css";
import './css/customStyle.css';
// import reportWebVitals from './reportWebVitals';

Amplify.configure(selectedConfig);

console.log(selectedConfig); //DEBUG AAM

ReactDOM.render(
  <AuthContextProvider>
    <App />
  </AuthContextProvider>
  ,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
