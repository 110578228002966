import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import PbCalNavBar from './PbCalNavBar';
import { Card, Container, Row, Col } from 'react-bootstrap';
import { DateTime } from "luxon";

import {getCalDates, getCalHeader, addDays} from '../js/PbCalLogic';
import { PbContext } from '../contexts/PbContextProvider';
// import { scanDB } from './PbDbUtils';
import {scanDBitemsDateRange, scanDBitemsMaintenance } from '../js/PbAPIUtils';
import { PbSpinner } from './PbSpinner';
import {  getTzAdjustedDate,  updatedDBdataWithCalTimezone, getPendingPinsData } from '../js/PbMiscUtils';

const StyledCalCard = styled(Card)`
  background-color: white;
  padding: 1%;
  text-align: center;
  font-size: 1.0vw;
  // margin-left: 0.1rem;
  padding-right: 0.1rem;
  padding-left: 0.1rem;
  border: 1px solid white;
  border-radius: 0.5rem;
  width: 100%;
`

const StyledCalContainer = styled(Container)`
  width: 80%;  
`

const StyledPbWeekContainer = styled(Row) `
  margin-top: 1rem;
  display: grid;
  font-size: 1.2vw;
  font-weight: 600;
  align-content: space-around;
  grid-gap: 1px;
  grid-row-gap: 0.1px;
  grid-template-columns: repeat(7, 1fr);
  grid-auto-rows: 2rem;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
`
const StyledPbDayGridContainer = styled(Row) `
  display: grid;
  // max-width: 80%;
  // align-content: space-around;
  grid-gap: 1px;
  grid-row-gap: 0.1px;
  grid-template-columns: repeat(7, 1fr);
  grid-auto-rows: 4rem;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
`

const StyledCol = styled(Col)`
  font-size: 1.0vw;
  text-align: left;
  cursor: pointer;
  color: ${props => props.color || "black"};
  background-color: ${props => props.bgcolor};
  border:  ${props => props.dayborder || '1px solid #dee2e6!important'};
  &:hover{
    border: 0.2rem solid #e47911!important;
    text-shadow: 0.2px 0.2px;
  }
`

const StyledWeekCol = styled(Col)`
  text-align: left;
  cursor: pointer;
  color: ${props => props.color || "black"};
  font-weight: ${props => props.fontWeight || 400};
  text-shadow: 0.2px 0.2px;
`

export default function PbCalViewer(props) {
  const [idxBorder, setIdxBorder] = useState({'placeHolder': null});
  const [ pbState, pbDispatch] = useContext(PbContext);
  let calHeader = getCalHeader(pbState.currentMonth);
  

  //Check if given day is in current month based on given offset
  const isCurrentMonth = (day, monthOffset) => {
    return DateTime.local().plus({month: monthOffset}).month === DateTime.fromISO(day).month;
  }

  let daysOfWeek = ['Sun', 'Mon', 'Tues', 'Wed', 'Thu', 'Fri', 'Sat'];

  function getDayEventsCount(dbData, day){
    let count = 0;

    for ( const k in dbData){
      if(getTzAdjustedDate(dbData[k]['startDate'], dbData[k]['timeZone'], pbState.timeZone).split('T')[0] <= day &&
      getTzAdjustedDate(dbData[k]['endDate'], dbData[k]['timeZone'], pbState.timeZone).split('T')[0]  >= day 
      ){ 
        count ++;
      }
    }
    return count > 0 ? `${count > 1 ? count + ' maintenances' : count + ' maintenance'}` : '';
  }


  function getDayEvents(dbData, day){
    console.log("day=> " + day)
    let eachDayData = Object.fromEntries(
      Object.entries(dbData).filter(
        ([k,v]) => dbData[k]['startDate'].split('T')[0] <= day  && dbData[k]['endDate'].split('T')[0]  >= day 
    ));
    return eachDayData;
  }

  /*  
     As soon as component loads, hide "New Maintenance Entry" and "View Reports" component.
     Upon getting DB data, show the Calendar with received data.     
  */
  useEffect( () => {
    pbDispatch({ type: 'DB_DATA_RECEIVED', dbDataReceived: false });
    pbDispatch({ type: 'HIDE_REPORT_DATA' }); 
    pbDispatch({ type: 'HIDE_MAINTENANCE_FORM' });
    pbDispatch({ type: 'HIDE_VIEW_REPORTS' });
    // pbDispatch({ type: 'HIDE_REPORTS_PANE' });

    // scanDB().then( data => {
    let dateArray = getCalDates(pbState.currentMonth);
    scanDBitemsDateRange(addDays(dateArray[0], -2).split('T')[0], addDays(dateArray[dateArray.length - 1], 2).split('T')[0]).then( data => {
      pbDispatch({ type: 'SET_DB_DATA', dbData: {...updatedDBdataWithCalTimezone(data, pbState.timeZone)} });
      pbDispatch({ type: 'SET_SELECTED_VIEWPANE_DATA', selectedViewPaneData: {} });
      pbDispatch({ type: 'DB_DATA_RECEIVED', dbDataReceived: true });
      pbDispatch({ type: 'SHOW_DAY_CALENDAR' });
      pbDispatch({ type: 'SET_CLEAR_RECORDS', clearRecords: false });
      pbDispatch({type: 'SHOW_DB_DATA', showDBdata: true});
    });
    scanDBitemsMaintenance().then(data => {
      pbDispatch({ type: 'SET_PENDING_PINS_DATA', pendingPinsData: {...getPendingPinsData(data)} });
    })
  },[]);

  return (
    <StyledCalCard>
      { !pbState.dbDataReceived ? 
        <React.Fragment>
          <StyledCol className={"col-12 border "} >
            < PbSpinner />
          </StyledCol>
        </React.Fragment>
        : 
        <StyledCalContainer>
          {/* Calendar Header */}
          <PbCalNavBar 
            calHeader={calHeader}
          />
          
          {/* Week Header */}
          <StyledPbWeekContainer> 
            {
              daysOfWeek.map((weekday) => {
                return (
                  <StyledWeekCol className={"col-12 border "} 
                    key={weekday}
                    >{weekday}<br />
                  </StyledWeekCol>
                )
              })
            }
          </StyledPbWeekContainer>
          {/* Day of month block */}
          {pbState.showDayCal && <StyledPbDayGridContainer>
            {console.dir(pbState)} 
            {
              getCalDates(pbState.currentMonth).map((day) => {
                  let isToday = DateTime.local().toString().split('T')[0] === day.split('T')[0]
                  let colColor = isToday ? '#45A27D' : 'black';
                  let fontWeight = isToday ? '800' : null;
                  let bgcolor = isToday ? '#ffbc3d' : null;
                  let currentDayData = getDayEventsCount(pbState.dbData, day.split('T')[0]);

                  return (
                    <StyledCol className={"col-12"}  dayborder={idxBorder[day.split('T')[0]]}
                      color= { colColor}
                      fontWeight = {fontWeight}
                      bgcolor = {bgcolor}
                      key={day.split('T')[0]}
                      onClick={(el) => {
                          console.log("Day Data :" + day.split('T')[0]);
                          pbDispatch({type: 'SHOW_REPORT_DATA'});
                          pbDispatch({type: 'SHOW_VIEW_REPORTS'});
                          pbDispatch({ type: 'SET_SELECTED_VIEWPANE_DATA', selectedViewPaneData: getDayEvents(pbState.dbData, day.split('T')[0]) });
                          pbDispatch({type: 'SHOW_DB_DATA'});
                          pbDispatch({ type: 'SET_SEARCH_BY_MONTH', searchByMonth: false });
                          pbDispatch({ type: 'IS_DAY_SELECTED', setDaySelected: true });
                          setIdxBorder({[day.split('T')[0]]: idxBorder[day.split('T')[0]] === '0.2rem solid #84de02' ? null : '0.2rem solid #84de02' });
                        }
                      }
                    > 
                    {
                      pbState.dbDataReceived ?
                        ( isCurrentMonth(day, pbState.currentMonth)
                          ? <React.Fragment>
                              <Row style={{color: '#006400'}}>
                                <Col style={{color: 'black', fontWeight: '400'}} > {DateTime.fromISO(day).day} </Col>
                              </Row>
                              <Row style={{color: '#006400'}}>
                                <Col> {currentDayData} </Col>
                              </Row>
                            </React.Fragment>
                          :
                          <React.Fragment>
                            <Row style={{color: '#A8A8A8'}}>
                              <Col style={{fontWeight: '400'}} > {DateTime.fromISO(day).day} </Col>
                            </Row>
                            <Row style={{color: '#A8A8A8'}}>
                              <Col> {currentDayData} </Col>
                            </Row>
                          </React.Fragment>
                        )
                      : null
                    }
                    </StyledCol>
                  )
                })
            }
          </StyledPbDayGridContainer>}
        </StyledCalContainer>
      }
    </StyledCalCard>
  );
}
